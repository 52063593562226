import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*{
     
}
`;

export default GlobalStyle;
