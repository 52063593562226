import styled from "styled-components";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TimerPicker = styled(TimePicker)`
  outline: none;
  width: 250px;
`;

export const ImageLogo = styled.img`
  width: 20%;
  height: 20%;
  justify-self: center;
  align-items: center;
`;

export const Title = styled.h1`
  color: #000000;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  padding-top: 0.5rem;
  font-family: SUITE;
`;

export const Label = styled.label`
  color: #454545;
  font-family: "SUITE";
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: 2px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

export const SubLabel = styled.label`
  font-family: "SUITE";
  font-size: 1.25rem;
  font-weight: 600;
  color: #454545;
  margin-right: 1rem;
`

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25%;
`;

export const DigitalClocks = styled(DigitalClock)`
  left: 0;
  right: 0;
  max-width: 200px;
  height: 300px;
  background-color: beige;
`;

// export const SelectWrapper = styled.div`
//   flex: ;
// `
