export function setLocal(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }
  
  export function removeLocal(key: string) {
    window.localStorage.removeItem(key);
  }
  
  export function getLocal(key: string) {
    return window.localStorage.getItem(key);
  }
  
  export function setSession(key: string, value: string) {
    window.sessionStorage.setItem(key, value);
  }
  
  export function getSession(key: string) {
    return window.sessionStorage.getItem(key);
  }
  
  export function removeSession(key: string) {
    return window.sessionStorage.removeItem(key);
  }
  