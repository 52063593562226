import { useParams } from "react-router-dom";
import { getCurrentBuildingAPI, getCurrentBuildingInfoAPI, getUserBuildingHistoryAPI } from "../../api/fszAPI";
import { useEffect, useState } from "react";
import { UserBuilding, UserBuildingRequestResults } from "../../Models/User";
import AddTimeSelect from "./AddTimeSelect";
import TimeSelect from "./TimeSelect";
import { DotLoader } from "react-spinners";
import Swal from "sweetalert2";

const TimePage = () => {
  const { userGuid } = useParams();
  const [userBuilding, SetUserBuilding] = useState<UserBuilding | null>(null);
  const [buildingName, setBuildingName] = useState("");

  useEffect(() => {
    const fetchEndTime = async () => {
      try {
        const result = await getCurrentBuildingInfoAPI(String(userGuid));

        if (result) {
          if (result.resultState === UserBuildingRequestResults.Success) {
            const currentBuilding = await getCurrentBuildingAPI(result.userBuilding?.buildingID);
            SetUserBuilding(result.userBuilding);

            if(currentBuilding)
              setBuildingName(currentBuilding.buildingName);
          }
          else {
            if (result.resultState === UserBuildingRequestResults.Expired) {
              const history = await getUserBuildingHistoryAPI(String(userGuid));

              if (history.resultState === UserBuildingRequestResults.Success) {
                window.location.replace(`/zone/${history.userBuildingHistory.buildingID}`);
              }
            }
          }
        } else {
          Swal.fire({
            title: "사용자 정보 조회 실패",
            text: "인터넷 상태를 확인한 후 다시 시도해주세요",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "사용자 정보 조회 실패",
          text: "인터넷 상태를 확인한 후 다시 시도해주세요",
        });
      }
    };

    fetchEndTime();
  }, []);

  return userBuilding ? (
    userBuilding.endTime != null && userBuilding.startTime != null ? (
      <AddTimeSelect userInfo={userBuilding} buildingName={buildingName} />
    ) : (
      <TimeSelect userInfo={userBuilding} />
    )
  ) : (
    <DotLoader />
  );
};

export default TimePage;
