import {
  Log,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client";
// import { BASE_AUTH_ADDRESS, REDIRECT_ADDRESS } from "@constants/urls";
import axios from "axios";
import jwt, { jwtDecode } from "jwt-decode";
import { getLocal, removeLocal, setLocal } from "./LocalStorageHelper";
import { api } from "../api/baseURL";

// const authUrl = "http://localhost:5000";
// const fszUrl = "http://localhost:3000";

const authUrl = "https://auth2.bdapp.kr";
const fszUrl = "https://fsz.bdapp.ai";

interface AccessToken {
  keeploggedin: string;
}

const userManagerConfig: UserManagerSettings = {
  client_id: "FSZ",
  client_secret: "secret",
  redirect_uri: `${fszUrl}/callback`,
  response_type: "code",
  scope: "openid offline_access profile api1",
  authority: authUrl,
  silent_redirect_uri: `${fszUrl}/silentRenew`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,
  post_logout_redirect_uri: fszUrl,
  userStore: new WebStorageStateStore({ store: localStorage }),
};

const userManager = new UserManager(userManagerConfig);

userManager.events.addUserLoaded((user) => {
  //Raised when a user session has been established (or re-established).
  console.log("addUserLoaded");

  const accessToken = jwtDecode<AccessToken>(user.access_token);

  console.log("KeepLoggedIn : " + accessToken.keeploggedin);

  if (accessToken.keeploggedin == "True") {
    saveUserToLocalStorage(user);
  }

  // axios.defaults.headers.common["Authorization"] = "Bearer " + user.access_token;

  api.defaults.headers.common["Authorization"] = "Bearer " + user.access_token;
});

userManager.events.addUserUnloaded(() => {
  //Raised when a user session has been terminated.
  console.log("addUserUnloaded");
  deleteUserFromLocalStorage();
});

userManager.events.addAccessTokenExpiring(() => {
  //Raised prior to the access token expiring.

  console.log("addAccessTokenExpiring");
  userManager.signinSilent();
});

userManager.events.addAccessTokenExpired(() => {
  console.log("addAccessTokenExpired");
  userManager.signinSilent();
});

userManager.events.addSilentRenewError((error) => {
  //Raised when the automatic silent renew has failed.

  console.log("addSilentRenewError");

  userManager.removeUser();
  deleteUserFromLocalStorage();
});

userManager.events.addUserSignedIn(() => {
  //Raised when the user is signed in.

  console.log("addUserSignedIn");
});

userManager.events.addUserSignedOut(() => {
  console.log("addUserSignedOut");
  //Raised when the user's sign-in status at the OP has changed.
  deleteUserFromLocalStorage();
});

userManager.events.addUserSessionChanged(() => {
  //Raised when the user session changed (when monitorSession is set)

  console.log("addUserSessionChanged");
});

export const deleteUserFromLocalStorage = () => {
  removeLocal("id_token");
  removeLocal("session_state");
  removeLocal("access_token");
  removeLocal("refresh_token");
  removeLocal("token_type");
  removeLocal("scope");
  removeLocal("profile");
  removeLocal("expires_at");
  removeLocal("state");
};

export const saveUserToLocalStorage = (user: User) => {
  const expireDate = Date.now();
  const result = new Date(expireDate);
  result.setDate(result.getDate() + 365);

  console.log("Saving User To LocalStorage : " + user.id_token);

  setLocal("id_token", user.id_token);
  if (user.session_state)
    setLocal("session_state", user.session_state.toString());
  setLocal("access_token", user.access_token);

  if (user.refresh_token) setLocal("refresh_token", user.refresh_token);
  setLocal("token_type", user.token_type);
  setLocal("scope", user.scope);
  setLocal("profile", JSON.stringify(user.profile));
  setLocal("expires_at", JSON.stringify({ expires_at: user.expires_at }));
  setLocal("state", user.state);
};

export const onLogin = (redirect_uri?: string) => {
  if (redirect_uri) userManager.signinRedirect({ redirect_uri });
  else userManager.signinRedirect();
};

export const onLogout = () => {
  userManager
    .getUser()
    .then((user) => {
      if (user) {
        deleteUserFromLocalStorage();
        userManager.removeUser();
        userManager.signoutRedirect({ id_token_hint: user.id_token });
      }
    })
    .catch((err) => {
      deleteUserFromLocalStorage();
      userManager.removeUser();
      userManager.signoutRedirect();
    });
};

export const KeepLoggedIn = async () => {
  const cookie_id_token = getLocal("id_token");

  if (cookie_id_token) {
    const cookie_session_state = getLocal("session_state");
    const cookie_access_token = getLocal("access_token");
    const cookie_refresh_token = getLocal("refresh_token");
    const cookie_token_type = getLocal("token_type");
    const cookie_scope = getLocal("scope");
    const cookie_state = getLocal("state");

    const user = new User({
      id_token: cookie_id_token,
      session_state: cookie_session_state!.toString(),
      access_token: cookie_access_token!.toString(),
      refresh_token: cookie_refresh_token!.toString(),
      token_type: cookie_token_type!.toString(),
      scope: cookie_scope!.toString(),
      profile: JSON.parse(getLocal("profile")!.toString()),
      expires_at: JSON.parse(getLocal("expires_at")!.toString()),
      state: cookie_state,
    });

    userManager.storeUser(user).then((user) => {
      console.log("stored user calling signinsilent");

      userManager
        .signinSilent()
        .then((user) => {})
        .catch((error) => {
          userManager.removeUser();
          userManager.signoutRedirect({
            post_logout_redirect_uri: fszUrl,
            redirect_uri: fszUrl,
          });
        });
    });
  } else {
    console.log("User : No User in cookie");
  }
};

KeepLoggedIn();

export const isLoggedIn = async () => {
  // if(!getLocal(`oidc.user:http://localhost:3001:FSZ`))
  //     return false;

  // //TODO: safari에 없음?
  // const oidcStorage = JSON.parse(getLocal(`oidc.user:http://localhost:3001:FSZ`)!.toString());

  // if (oidcStorage) {
  //     return !!oidcStorage.access_token;
  // }

  return (await userManager.getUser()) !== null;
};

export const getUserId = async () => {
  var user = await userManager.getUser();
  if (user) return user.profile.sub;
  else return "";
};

export const ResetAccessToken = async () => {
  const user = await userManager.getUser();

  if (user) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.access_token;
  }
};

export default userManager;
