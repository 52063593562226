import BDAppLogo from "../../asset/images/bdappiconhori.svg";
import FszCircleImage from "../../asset/images/firesafezone.svg";
import FszCircleImage_vi from "../../asset/images/firesafezone_vi.svg";
import {
  deleteUserBuildingAPI,
  extendTimeAPI,
  getCurrentBuildingAPI,
} from "../../api/fszAPI";
import styled from "styled-components";
import { getCurrentDate, getTimeDiff } from "../../utils/dateTimeHelper";
import {
  DeleteUserBuildingResults,
  ExtendTimeResults,
} from "../../Models/Edit";
import { UserBuilding } from "../../Models/User";
import { Label } from "./TimeSelect.styled";
import Select from "react-select";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { FszImage, CheckButton, LayoutBlock } from "../Shared.styled";
import { PhoneNumberLabel } from "../Main/Main.styled";
import Picker from "../../components/picker";
import { useAtomValue } from "jotai";
import { languageModeAtom } from "../../atoms/language";
import { KOREAN } from "../../constants/language";
import { Trans, useTranslation } from "react-i18next";

type AddTimeSelectProps = {
  userInfo: UserBuilding | null;
  buildingName: string;
};

const path = "pages.time";

const AddTimeSelect = ({ userInfo, buildingName }: AddTimeSelectProps) => {
  const hourOptions = [
    "",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "24",
    "  ",
  ];
  const [remainTime, setRemainTime] = useState("");
  const currentLanguage = useAtomValue(languageModeAtom);
  const { t } = useTranslation();

  const minuteOptions = [
    { value: "0", label: "0" },
    { value: "30", label: "30" },
  ];

  const [selectedHour, setSelectedHour] = useState(1);
  const [WantExtend, setWantExtend] = useState(false);

  useEffect(() => {
    const updateTime = () => {
      const current = getCurrentDate();
      const remain = getTimeDiff(
        current,
        new Date(userInfo?.endTime ?? new Date())
      );
      const hours = Math.floor(remain / (1000 * 60 * 60));
      const minutes = Math.floor((remain % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remain % (1000 * 60)) / 1000);
      setRemainTime(
        remainTime ? `${hours}:${minutes}:${seconds}` : "Loading.."
      );
    };
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, [userInfo?.endTime, remainTime]);

  const putEndTime = async () => {
    try {
      if (userInfo != null && userInfo.endTime != null) {
        let extendEndTime = new Date(userInfo?.endTime);
        extendEndTime.setHours(extendEndTime.getHours() + selectedHour);
        const result = await extendTimeAPI({
          guid: userInfo?.guid ?? "",
          endTime: extendEndTime,
        });

        if (result.resultState === ExtendTimeResults.Success) {
          window.location.reload();
        } else {
          Swal.fire({
            title: "연장 실패",
            text: `${result.message}, 확인한 후 다시 시도해주세요`,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        title: "연장 실패",
        text: "연장한 시간을 확인하시거나, 인터넷 상태를 확인한 후 다시 시도해주세요",
      });
    }
  };

  const deleteUserBuilding = async () => {
    try {
      const result = await deleteUserBuildingAPI(userInfo?.guid ?? "");
      if (result.resultState === DeleteUserBuildingResults.Success) {
        window.location.replace(`/zone/${userInfo?.buildingID}`);
      } else {
        Swal.fire({
          title: "삭제 실패",
          text: `${result.message}, 확인한 후 다시 시도해주세요`,
        });
      }
    } catch (error) {
      Swal.fire({
        title: "삭제 실패",
        text: "인터넷 상태를 확인한 후 다시 시도해주세요",
      });
    }
  };

  const handleExtend = () => {
    if (!WantExtend) {
      setWantExtend(true);
      return;
    }

    putEndTime();
  };

  const handleDelete = () => {
    deleteUserBuilding();
  };

  return (
    <>
      <BdAppLogo src={BDAppLogo} />
      <Container>
        <CircleImage
          src={currentLanguage === KOREAN ? FszCircleImage : FszCircleImage_vi}
        />
        <div>
          <h1>{buildingName}</h1>
          <h2>
            <Trans
              i18nKey={`${path}.message`}
              values={{ phoneNumber: userInfo?.phoneNumber }}
            />
          </h2>
          <h2>
            <Trans
              i18nKey={`${path}.remainTime`}
              values={{ remainTimeText: remainTime }}
              components={{ span: <span /> }}
            />
          </h2>
        </div>
        <ButtonContainer>
          <DeleteButton onClick={handleDelete}>
            <Trans i18nKey={`${path}.delete`} />
          </DeleteButton>
          <ExtendButton onClick={handleExtend}>
            {WantExtend ? t(`${path}.save`) : t(`${path}.addTime`)}
          </ExtendButton>
        </ButtonContainer>

        <TimeSelectorContainer>
          {WantExtend && (
            <>
              <Picker
                list={hourOptions}
                onSelectedChange={(v) => {
                  setSelectedHour(Number.parseInt(String(v)));
                }}
              />
              <span>
                <Trans i18nKey={`${path}.time`} />
              </span>
            </>
          )}
        </TimeSelectorContainer>
      </Container>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            position: "absolute",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "40%",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "10%",
                width: "100%",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <FszImage
                src={BDAppLogo}
                style={{
                  display: "flex",
                  alignSelf: "start",
                  justifySelf: "start",
                  height: "100%",
                  width: "25%",
                  marginLeft: "0.5rem",
                  marginTop: "0.5rem",
                }}
              />
            </div>
            <div
              style={{
                height: "90%",
                width: "100%",
              }}
            >
              <FszImage
                src={FszCircleImage}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "60%",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <PhoneNumberLabel
              style={{
                fontSize: "2.5rem",
                marginBottom: "2.5rem",
                marginTop: "1rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {buildingName}
            </PhoneNumberLabel>
            <PhoneNumberLabel
              style={{
                fontWeight: "500",
                justifySelf: "end",
                fontFamily: "SUITE",
                lineHeight: "1.25",
                marginBottom: "1rem",
              }}
            >
              안녕하세요,
              <br />
              {userInfo?.phoneNumber} 고객님
            </PhoneNumberLabel>
            <PhoneNumberLabel
              style={{
                fontWeight: "500",
                justifySelf: "end",
                fontFamily: "SUITE",
                marginBottom: "1rem",
              }}
            >
              고객님의 알람 설정 시간이
            </PhoneNumberLabel>
            <RemainLabel>
              {remainTime
                ? `${Math.floor(remainTime / 60)}시간 ${remainTime % 60}분`
                : "정보를 불러오는 중입니다."}
              <br />
            </RemainLabel>
            <PhoneNumberLabel
              style={{
                fontWeight: "500",
                justifySelf: "end",
                fontFamily: "SUITE",
                marginTop: "1rem",
                marginBottom: "3rem",
              }}
            >
              남았습니다.
            </PhoneNumberLabel>

            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteButton onClick={handleDelete}>등록삭제</DeleteButton>
              <ExtendButton onClick={handleExtend}>
                {WantExtend ? "저장" : "시간설정"}
              </ExtendButton>
            </div>

            {WantExtend ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "40%" }} />
                <Picker
                  list={hourOptions}
                  onSelectedChange={(v) => {
                    setSelectedHour(Number.parseInt(String(v)));
                  }}
                />
                <div style={{ width: "40%" }}>
                  <PhoneNumberLabel
                    style={{
                      color: "#555",
                      marginLeft: "0.5rem",
                      textAlign: "start",
                      fontWeight: "800",
                      fontSize: "1.75rem",
                      fontFamily: "SUITE",
                    }}
                  >
                    시간
                  </PhoneNumberLabel>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AddTimeSelect;

const BdAppLogo = styled.img`
  position: fixed;
  left: 1rem;
  top: 1rem;
  height: 1.8rem;
`;

const CircleImage = styled.img`
  margin-top: 2rem;
  /* height: 40%;
  max-width: 400px; */
  width: 100%;
  max-width: 350px;
  max-height: 40dvh;
`;

const Container = styled.div`
  /* height: 100dvh; */
  div {
    &:first-of-type {
      /* height: 30%; */
      & > h1 {
        font-family: "The Jamsil OTF";
        font-weight: 800;
        font-size: 1.75rem;
        margin-bottom: 1.5rem;
      }
      & > h2 {
        font-family: SUITE;
        color: #454545;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        & > span {
          color: #000;
          font-size: 1.75rem;
          font-weight: 700;
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  /* height: 5%; */
  width: 100%;
  padding: 0 0.5rem;
  max-width: 400px;
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 0 auto;
`;

const TimeSelectorContainer = styled.div`
  /* height: 20%; */
  margin: 0 auto;
  /* margin-top: 1rem; */
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  width: 100%;
  max-width: 400px;

  & > :first-child {
    grid-column: 2;
    width: 100%;
    align-self: center;
  }

  & > :last-child {
    grid-column: 3;
    width: 100%;
    align-self: center;
    color: #555;
    text-align: left;
    padding-left: 0.5rem;
    font-size: 1.75rem;
    font-weight: 800;
  }
`;

const AnnounceLabel = styled(Label)`
  font-weight: 700;
`;

const RemainLabel = styled(Label)`
  font-weight: 800;
  font-size: 2rem;
  color: black;
`;

const DeleteButton = styled(CheckButton)`
  background-color: #ff5353;
  width: 100%;
  font-size: 1.3rem;
`;

const ExtendButton = styled(CheckButton)`
  background-color: #454545;
  width: 100%;
  font-size: 1.3rem;
`;
const DescriptionLabel = styled(AnnounceLabel)`
  color: #a0a1a6;
  font-size: 1.15rem;
  justify-self: flex-end;
`;
