import i18n, { loadLanguages } from "i18next";
import { initReactI18next } from "react-i18next";
import { ENGLISH, KOREAN, VIETNAMESE } from "../constants/language";
import translationEn from "./en/translation.json";
import translationKo from "./ko/translation.json";
import translationVi from "./vi/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  vi: {
    translation: translationVi,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    supportedLngs: ["ko", "en", "vi"],
    fallbackLng: "ko",
    resources,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["navigator"],
      lookupCookie: "i18next",
      caches: ["cookie"],
      cookieMinutes: 1,

      // caches: ["cookie"], // 언어 정보를 쿠키에 저장
    },
  });
