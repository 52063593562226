import { useLocation, useNavigate } from "react-router-dom";
import userManager, { saveUserToLocalStorage } from "./AuthManager";
import { getLocal, removeLocal } from "./LocalStorageHelper";

export const CallBack = () => {
    const navigate = useNavigate();
    const location = useLocation();

    userManager
    .signinRedirectCallback()
    .then((user) => {
        const prevLocation = getLocal("prevLocation");
        removeLocal("prevLocation");

        navigate(prevLocation ?? location.state?.from ?? '/');

        }
    )
    .catch((error) => 
        {
            navigate('/');
        });

  return <div>Callback</div>;
};
