import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <Container>
      <FooterText>
        <span>이엠시티 주식회사 │</span>
        <span>사업자등록번호&nbsp;:&nbsp;789-86-01940│</span>
        <span>주소:&nbsp;대전 유성구 대덕대로512번길 20 B동 200-21호 │</span>
        <span>TEL:&nbsp;1855-1317 │</span>
        <SpanBold>개인정보처리방침</SpanBold>
        <br />
      </FooterText>
      <FooterText>
        <SpanBold>ⓒ emCT co., Ltd. All Rights Reserved.</SpanBold>
      </FooterText>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  width: 100%;
  min-height: 80px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  border-top: 1px solid #646464;

  @media screen and (max-width: 600px) {
    padding: 5px;
  }
`;

const FooterText = styled.div`
  width: 100%;
  color: #5e5e5e;

  font-family: "GothicA1";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    font-size: 13px;

    & > span {
      text-align: left;
      word-wrap: break-word;
      word-break: keep-all;
      text-overflow: clip;
    }
  }
`;

const SpanBold = styled.span`
  font-weight: 600;
`;
