import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Main from "../pages/Main/Main";
import Alarm from "../pages/Alarm/Alarm";
import TimePage from "../pages/TimeSelect/TimePage";
import AdminUser from "../pages/AdminUser/AdminUser";
import { SilentRenew } from "../auth/SilentRenew";
import { CallBack } from "../auth/CallBack";
import Home from "../pages/Home/Home";
import AuthWrapper from "../auth/AuthWrapper";
import { Login } from "../pages/Login/Login";
import userManager, { isLoggedIn } from "../auth/AuthManager";

export default function Router() {
  const [userData, setUserData] = useState(false);

  useEffect(() => {
    isLoggedIn()
      .then((result) => {
        if (!result) setUserData(false);
      })
      .catch(() => {
        setUserData(false);
      });
  });

  useEffect(() => {
    userManager.events.addUserLoaded((user) => {
      setUserData(true);
    });

    userManager.events.addUserUnloaded(() => {
      setUserData(false);
    });
  }, []);

  return (
    <Container>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/silentRenew" element={<SilentRenew />} />
        <Route path="/callback" element={<CallBack />} />
        <Route path="/alarm/:userGuid" element={<Alarm />} />
        <Route path="/zone/:buildingId" element={<Main />} />
        <Route path="/confirm/:userGuid" element={<TimePage />} />
        <Route path="/login" element={<Login />} />
        <Route element={<AuthWrapper userData={userData} />}>
          <Route path="/zone/admin/:buildingId" element={<AdminUser />} />
        </Route>

        {/* <Route path="/buildingdetail/:buildingId" element={<BuildingDetail />} /> */}
        {/* <Route path="/errorPage" element={<ErrorPage/>}/> */}
        {/*<Route path="/zone/admin/:buildingId" element={<AuthTest />} />*/}
      </Routes>
    </Container>
  );
}

export const Container = styled.div`
  /* max-width: 436px; */
  /* width: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* margin: auto; */
  /* height: 100%; */
`;
