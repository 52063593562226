export const SendMessageResults = {
  Success: 0,
  NotExists: 1,
  Error: 2,
} as const;
export type SendMessageResults =
  (typeof SendMessageResults)[keyof typeof SendMessageResults];
export const UserBuildingSignUpResults = {
  Exist: 0,
  NotExists: 1,
  Error: 2,
} as const;
export type UserBuildingSignUpResults =
  (typeof UserBuildingSignUpResults)[keyof typeof UserBuildingSignUpResults];
export const UserBuildingRequestResults = {
  Success: 0,
  Expired: 1,
  Error: 2,
} as const;
export type UserBuildingRequestResults =
  (typeof UserBuildingRequestResults)[keyof typeof UserBuildingRequestResults];

export interface SendMessageResponse {
  message: string;
  resultState: SendMessageResults;
  guid: string;
}

export interface SendMessageRequest {
  countryCode: string;
  buildingID: number;
  phoneNumber: string;
}

export interface UserBuildingSignUpRequest {
  buildingID: number;
  guid: string;
  startTime: Date;
  endTime: Date;
}

export interface UserBuildingSignUpResponse {
  message: string;
  resultState: UserBuildingSignUpResults;
}

export interface UserBuilding {
  guid: string;
  buildingID: number;
  phoneNumber: string;
  startTime: Date | null;
  endTime: Date | null;
}

export interface UserBuildingResponse {
  resultState: UserBuildingRequestResults;
  userBuilding: UserBuilding;
}

export interface UserBuildingHistoryResponse {
  resultState: UserBuildingRequestResults;
  userBuildingHistory: UserBuilding;
}

export interface Building {
  buildingId: number;
  buildingName: string;
  address: string;
  defaulttimeOfStay: number;
}

export interface User {
  id: number;
  name: string;
  phoneNumber: string;
  type: number;
  buildingId: number;
  buildingNumber: string;
  unit: string;
}
