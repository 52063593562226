import React, { ChangeEvent, useEffect, useState } from "react";
import { numberCheck } from "../../utils/numberCheck";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Success from "../Success/Success";
import { getCurrentBuildingAPI, sendMessageAPI } from "../../api/fszAPI";
import { SendMessageResults } from "../../Models/User";
import Swal from "sweetalert2";
import { FszButton, FszImage, FszInput } from "../Shared.styled";
import BDAppLogo from "../../asset/images/bdappiconhori.svg";
import FszIcon from "../../asset/images/fszicon.svg";
import FszIconVi from "../../asset/images/fsziconVi.svg";
import City from "../../asset/images/city.svg";
import City2 from "../../asset/images/city2.svg";
import { PhoneNumberLabel } from "./Main.styled";
import { TranslationObj } from "../Home/Home";
import { useAtomValue } from "jotai";
import { languageModeAtom } from "../../atoms/language";
import { Trans, useTranslation } from "react-i18next";
import { KOREAN, VIETNAMESE } from "../../constants/language";
import styled from "styled-components";
import fsz_logo from "../../asset/images/fsz_logo.svg";
import fsz_logo_title from "../../asset/images/fsz_logo_title.svg";
import fsz_logo_title_vi from "../../asset/images/fsz_logo_title_vi.svg";
import fsz_logo_title_en from "../../asset/images/fsz_logo_title_en.svg";

const path = "pages.main";
const keys = ["title", "description", "phoneLabel", "placeholder", "confirm"];

type ImageMap = {
  [key: string]: { [key: string]: string };
};
const imageMap: ImageMap = {
  fsz_logo_title: {
    ko: fsz_logo_title,
    vi: fsz_logo_title_vi,
    en: fsz_logo_title_en,
  },
};

export default function Main() {
  //상태를 useState를 통해 관리
  const selectOptionList = ["+82", "+84"];
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [IsSuccessState, setIsSuccessState] = useState(false);
  const [buildingName, setBuildingName] = useState("");
  const [content, setContent] = useState<TranslationObj>({});
  const currentLanguage = useAtomValue(languageModeAtom);
  const [countryCode, setCountryCode] = useState<string>("");

  useEffect(() => {
    if (currentLanguage === KOREAN) {
      setCountryCode("+82");
    } else if (currentLanguage === VIETNAMESE) {
      setCountryCode("+84");
    }
  }, [currentLanguage]);

  const { t, i18n } = useTranslation();

  let { buildingId } = useParams();

  useEffect(() => {
    const translationObj: TranslationObj = {};
    keys.forEach((key) => {
      const keyArray = key.split(".");
      if (keyArray.length > 1) {
        if (!translationObj[keyArray[0]]) {
          translationObj[keyArray[0]] = {};
        }
        translationObj[keyArray[0]][keyArray[1]] = t(`${path}.${key}`);
        return;
      }
      translationObj[key] = t(`${path}.${key}`);
    });
    setContent(translationObj);
  }, [currentLanguage]);

  const onChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (numberCheck(e.target.value)) setPhoneNumber(e.target.value);
  };

  const getBuildingInfo = async () => {
    try {
      if (buildingId) {
        const result = await getCurrentBuildingAPI(parseInt(buildingId));
        console.log(`result`, result);
        setBuildingName(result.buildingName);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getBuildingInfo();
  }, []);

  //NumberCheckAPI 호출
  //비동기 요청 관리, 성공 시 패스워드 확인 페이지로 이동, 실패 시 API 통신 에러 관련 경고 모달 표시
  const { mutate } = useMutation({
    mutationFn: sendMessageAPI,
    onSuccess: (data) => {
      if (data.resultState === SendMessageResults.Success) {
        if (data.guid) {
          localStorage.setItem("key", data.guid);
        }
        setIsSuccessState(true);
      } else {
        Swal.fire({
          title: "등록실패",
          text: "QR을 다시 찍어 시도하시거나 인터넷 상태를 확인한 후 다시 시도해주세요",
        });
      }
    },
    onError: () => {
      Swal.fire({
        title: "등록실패",
        text: "QR을 다시 찍어 시도하시거나 인터넷 상태를 확인한 후 다시 시도해주세요",
      });
    },
  });

  //전화번호 길이 확인, 너무 짧으면 경고 모달 표시.
  const onNavigateSignUp = () => {
    if (phoneNumber.length < 10) {
      console.log("setting warning state and message");
      return;
    }

    mutate({
      countryCode,
      phoneNumber: phoneNumber,
      buildingID: parseInt(buildingId ?? "0"),
    });
  };

  //JSX 반환하여 렌더링.
  //onChange 이벤트 핸들러를 사용하여 전화번호 입력 필드의 변경 사항 처리
  return (
    <>
      <Background>
        <BdAppLogo src={BDAppLogo} />
        <CityImage src={City} />
        <LogoBox>
          <img src={fsz_logo} alt="fsz_logo" />
          <img
            src={imageMap["fsz_logo_title"][currentLanguage]}
            alt="fsz_logo_title"
          />
        </LogoBox>
      </Background>
      {IsSuccessState ? (
        <Success messageKind="signUp" />
      ) : (
        <>
          <MainContainer>
            <h1>{buildingName}</h1>
            <h2>
              <Trans components={{ span: <span /> }}>
                {content.description}
              </Trans>
            </h2>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onNavigateSignUp();
              }}
            >
              <label htmlFor="phoneInput">{content.phoneLabel}</label>
              <PhoneInputBox>
                <Select
                  onChange={(e) => setCountryCode(e.target.value)}
                  value={countryCode}
                >
                  {selectOptionList.map((code, index) => {
                    return (
                      <option key={index} value={code}>
                        {code}
                      </option>
                    );
                  })}
                </Select>
                <Input
                  id="phoneInput"
                  placeholder={content.placeholder}
                  value={phoneNumber}
                  onChange={(e) => onChangePhoneNumber(e)}
                />
              </PhoneInputBox>
              <button type="submit">{content.confirm}</button>
            </Form>
          </MainContainer>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                position: "absolute",
                overflow: "hidden",
                width: "100%",
                height: "100%",
                alignItems: "center",
              }}
            >
              <FszImage
                src={City}
                style={{
                  backgroundImage:
                    "linear-gradient(334deg, #FFD4C5 9.74%, #FEADB2 98.61%)",
                  position: "relative",
                  height: "35%",
                  width: "255%",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
                position: "absolute",
                overflow: "hidden",
                width: "100%",
                height: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "40%",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "10%",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                >
                  <FszImage
                    src={BDAppLogo}
                    style={{
                      display: "flex",
                      alignSelf: "start",
                      justifySelf: "start",
                      height: "100%",
                      width: "25%",
                      marginLeft: "0.5rem",
                      marginTop: "0.5rem",
                    }}
                  />
                </div>

                <FszImage
                  src={currentLanguage === KOREAN ? FszIcon : FszIconVi}
                  style={{
                    height: "90%",
                    width:
                      window.innerHeight > window.innerWidth ? "40%" : "10%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  height: "60%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <PhoneNumberLabel
                  style={{
                    fontSize: "2.5rem",
                    marginBottom: "2rem",
                    marginLeft: "0.75rem",
                    marginRight: "0.75rem",
                  }}
                >
                  {buildingName}
                </PhoneNumberLabel>
                <PhoneNumberLabel
                  style={{ color: "#FF9457", marginBottom: "4rem" }}
                >
                  이곳은{" "}
                  <span style={{ fontSize: "1.75rem", fontWeight: "600" }}>
                    비디앱
                  </span>
                  과 함께하는
                  <br />
                  화재 안심 구역입니다.
                </PhoneNumberLabel>
                <PhoneNumberLabel
                  style={{ fontWeight: "400", justifySelf: "end" }}
                >
                  휴대전화 번호 입력
                </PhoneNumberLabel>
                <FszInput
                  style={{
                    width: "90%",
                    marginBottom: "1.5rem",
                    marginLeft: "0.75rem",
                    marginRight: "0.75rem",
                  }}
                  maxLength={11}
                  placeholder="-제외 숫자만 입력하세요."
                  value={phoneNumber}
                  onChange={(e) => onChangePhoneNumber(e)}
                />
                <FszButton Width="45%" Height="45px" clicked={onNavigateSignUp}>
                  확인
                </FszButton>
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
}

const BdAppLogo = styled.img`
  position: fixed;
  left: 1rem;
  top: 1rem;
  height: 1.8rem;
`;

const Background = styled.div`
  width: 100%;
  height: 250px;
  background: linear-gradient(334deg, #ffd4c5 9.74%, #feadb2 98.61%);

  position: relative;

  overflow: hidden;
`;

const CityImage = styled.img`
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 95%;
`;

const LogoBox = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;

  & > img:first-child {
    width: 100%;
    max-width: 100px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  & > img:nth-child(2) {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;

  h1 {
    font-family: "TheJamsil";
    font-size: 2rem;
    font-weight: 500;
    margin: 2rem auto;
  }

  h2 {
    max-width: 400px;
    font-family: "TheJamsil";
    color: #ff4e4e;
    font-size: 1.5rem;
    margin: 0 auto;
    margin-bottom: 2rem;

    word-wrap: break-word;
    word-break: keep-all;

    font-weight: 500;

    span {
      font-size: 1.7rem;
      font-weight: 700;
    }
  }

  label {
    font-family: "SUITE";
    font-size: 1.5rem;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }

  button {
    margin: 0 auto;
    margin-top: 1rem;
    border: none;
    background-color: transparent;

    font-family: "SUITE";
    font-size: 1.5rem;
    color: white;

    padding: 0.75rem 3rem;
    background-color: #454545;
    border-radius: 50px;
  }
`;

const Form = styled.form`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  label {
    display: inline-block;
    margin-bottom: 1rem;
  }
`;

const PhoneInputBox = styled.div`
  width: 100%;
  max-width: 400px;

  display: flex;
  gap: 1rem;
`;

const Select = styled.select`
  width: calc(20% - 0.5rem);
  min-width: 80px;
  font-size: 1.5rem;
  font-family: "SUITE";
  option {
    font-size: 1.5rem;
    font-family: "SUITE";
  }
`;

const Input = styled.input`
  width: calc(80% - 0.5rem);
  font-size: 1.5rem;
  font-weight: 300;
  font-family: "SUITE";
  text-align: center;

  border: none;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, 1);

  padding: 0.25rem;
  /* border: none;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, 1);

  font-size: 1.5rem;
  font-weight: 300;
  font-family: "SUITE";
  padding: 0.5rem;
  text-align: center;

  background-color: #f7f8fc; */
`;
