export const ExtendTimeResults = {
    Success: 0,
    Error: 1
} as const;
export type ExtendTimeResults = typeof ExtendTimeResults[keyof typeof ExtendTimeResults];

export const DeleteUserBuildingResults = {
    Success: 0,
    Error: 1
} as const;
export type DeleteUserBuildingResults = typeof DeleteUserBuildingResults[keyof typeof DeleteUserBuildingResults];

export interface ExtendTimeRequest{
    guid:string;
    endTime:Date;
}

export interface ExtendTimeResponse{
    message: string;
    resultState: ExtendTimeResults;
}

export interface DeleteUserBuildingResponse{
    message: string;
    resultState: DeleteUserBuildingResults;
}