import { FszImage } from "../Shared.styled";
import BDAppLogo from "../../asset/images/logo.svg";
import BDAppLogoEn from "../../asset/images/logo_en.svg";
import { PhoneNumberLabel } from "../Main/Main.styled";
import HomeImage1 from "../../asset/images/homeimage1.svg";
import HomeImage2 from "../../asset/images/homeimage2.svg";
import HomeImage3 from "../../asset/images/homeimage3.svg";
import HomeImage4 from "../../asset/images/homeimage4.svg";
import HomeImage1Vi from "../../asset/images/homeimage1vi.svg";
import HomeImage2Vi from "../../asset/images/homeimage2vi.svg";
import HomeImage3Vi from "../../asset/images/homeimage3vi.svg";
import HomeImage4Vi from "../../asset/images/homeimage4vi.svg";
import HomeImage1En from "../../asset/images/home_image_01_en.svg";
import HomeImage2En from "../../asset/images/home_image_02_en.svg";
import HomeImage3En from "../../asset/images/home_image_03_en.svg";
import HomeImage4En from "../../asset/images/home_image_04_en.svg";
import Up from "../../asset/images/up.svg";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { ENGLISH, KOREAN } from "../../constants/language";
import { useAtomValue } from "jotai";
import { languageModeAtom } from "../../atoms/language";

const path = "pages.home";
const keys = [
  "title",
  "description",
  "howToUse.title",
  "howToUse.description1",
  "howToUse.description2",
  "howToUse.description3",
  "howToUse.description4",
  "end",
];

type ImageMap = {
  [key: string]: { [key: string]: string };
};
const imageMap: ImageMap = {
  logo: { ko: BDAppLogo, vi: BDAppLogoEn, en: BDAppLogoEn },
  home_image_01: { ko: HomeImage1, vi: HomeImage1Vi, en: HomeImage1En },
  home_image_02: { ko: HomeImage2, vi: HomeImage2Vi, en: HomeImage2En },
  home_image_03: { ko: HomeImage3, vi: HomeImage3Vi, en: HomeImage3En },
  home_image_04: { ko: HomeImage4, vi: HomeImage4Vi, en: HomeImage4En },
};

export interface TranslationObj {
  [key: string]: any;
}

const Home = () => {
  const [showButton, setShowButton] = useState(false);
  const [content, setContent] = useState<TranslationObj>({});
  const { t, i18n } = useTranslation();
  const currentLanguage = useAtomValue(languageModeAtom);

  useEffect(() => {
    const translationObj: TranslationObj = {};
    keys.forEach((key) => {
      const keyArray = key.split(".");
      if (keyArray.length > 1) {
        if (!translationObj[keyArray[0]]) {
          translationObj[keyArray[0]] = {};
        }
        translationObj[keyArray[0]][keyArray[1]] = t(`${path}.${key}`);
        return;
      }
      translationObj[key] = t(`${path}.${key}`);
    });
    setContent(translationObj);
  }, [i18n.language]);

  // useEffect(() => {
  //   console.log(`HOME currentLanguage`, currentLanguage);
  // }, [currentLanguage]);

  const handleScrollTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        padding: "1rem",
      }}
    >
      <FszImage
        src={imageMap["logo"][currentLanguage]}
        style={{
          height: "100px",
          marginTop: "6rem",
          marginBottom: "2rem",
        }}
      />
      <Title $lang={currentLanguage}>
        <Trans>{content?.title}</Trans>
      </Title>
      {currentLanguage !== ENGLISH && (
        <PhoneNumberLabel
          style={{ fontSize: "1.5rem", marginBottom: "4rem", color: "#A7A7A7" }}
        >
          Fire Safe Zone
        </PhoneNumberLabel>
      )}
      <Description $textAlign="left">
        <Trans components={{ span: <span /> }}>{content?.description}</Trans>
      </Description>
      <SubTitle> {content?.howToUse?.title}</SubTitle>
      <SubDescription $index="1">
        {content.howToUse?.description1}
      </SubDescription>
      <SubDescriptionImage src={imageMap["home_image_01"][currentLanguage]} />
      <SubDescription $index="2">
        {content.howToUse?.description2}
      </SubDescription>
      <SubDescriptionImage src={imageMap["home_image_02"][currentLanguage]} />
      <SubDescription $index="3">
        {content.howToUse?.description3}
      </SubDescription>
      <SubDescriptionImage src={imageMap["home_image_03"][currentLanguage]} />
      <SubDescription $index="4">
        {content.howToUse?.description4}
      </SubDescription>
      <SubDescriptionImage src={imageMap["home_image_04"][currentLanguage]} />
      <Description $textAlign="center">
        <Trans components={{ span: <span /> }}>{content.end}</Trans>
      </Description>
      {showButton ? (
        <FszImage
          onClick={handleScrollTop}
          style={{
            alignSelf: "center",
            width: "50px",
            paddingTop: "4rem",
            paddingBottom: "4rem",
          }}
          src={Up}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Home;

const Title = styled.h1<{ $lang: string }>`
  color: #454545;
  text-align: center;
  font-family: SUITE;
  font-style: normal;
  font-weight: 800;
  margin: 0;
  margin-bottom: 1rem;
  ${(props) =>
    props.$lang === KOREAN
      ? css`
          font-size: 45px;
        `
      : css`
          font-size: 26px;
        `};
  ${(props) =>
    props.$lang === ENGLISH &&
    css`
      font-size: 43px;
      padding-bottom: 4rem;
    `}
`;

const Description = styled.p<{ $textAlign: string }>`
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  text-align: ${({ $textAlign }) => $textAlign};
  font-family: SUITE;
  font-size: 1.25rem;
  line-height: 1.7rem;
  color: #454545;
  font-weight: 500;
  padding-bottom: 4rem;

  word-wrap: break-word;
  word-break: keep-all;

  span {
    /* font-size: 1.6rem; */
    font-weight: 800;
  }
`;

const SubTitle = styled.h2`
  color: #000;
  text-align: center;
  font-family: SUITE;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;

  padding-bottom: 2rem;
`;

const SubDescription = styled.p<{ $index: string }>`
  margin: 0 auto;
  width: 100%;
  max-width: 400px;

  color: #000;
  font-family: SUITE;
  font-weight: 700;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.5rem;

  padding-left: 1.5rem;

  position: relative;

  word-wrap: break-word;
  word-break: keep-all;

  &::before {
    position: absolute;
    content: "${({ $index }) => $index && `${$index}.`}";
    left: 0.2rem;
  }
`;

const SubDescriptionImage = styled.img`
  width: 100%;
  max-width: 400px;
  padding: 2.5rem;
`;
