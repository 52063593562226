import styled from "styled-components";

export const Block = styled.div`
  height: 15%;
`;

export const MiniBlock = styled.div`
  height: 5%;
`;

export const ContentWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const PhoneNumberLabel = styled.h2`
  font-weight: 500;
  color: #000000;
  font-family: "TheJamsil";
  font-size: 1.5rem;
`;

export const SignUpButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: max-content;
  text-decoration: underline;
  margin: auto;
`;

export const ModalBox = styled.div`
  position: absolute;
  z-index: 3;
  max-width: 300px;
  max-height: 140px;
  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  background-color: white;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  line-height: 22px;
`;

export const ModalButtonWrap = styled.div`
  display: flex;
  gap: 8px;
`;

export const ModalButton = styled.button`
  padding: 8px 24px;
`;

export const ModalBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 12%);
`;
export const textBold = styled.h3`
  color: #414141;
  font-size: 30px;
  font-weight: 600;
  line-height: normal;

  padding-top: 30px;
  padding-bottom: 5px;
`;
