import userManager, { saveUserToLocalStorage } from "./AuthManager";

export const SilentRenew = () => {
  userManager
    .signinSilentCallback()
    .then((user) => {
      if (user) {
        saveUserToLocalStorage(user);
      } else {
        console.log("SilentRenew: user err ", user);
      }
    })
    .catch((error) => {
      console.log("SilentRenew catch err", error);
    });

  return <div>SilentRenew</div>;
};
