import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import BDAppLogo from "../../asset/images/logo.svg";
import {
  ImageLogo,
  Label,
  SubLabel,
  TimeWrapper,
  Title,
} from "./TimeSelect.styled";
import { useMutation } from "@tanstack/react-query";
import { userBuildingSignUpAPI } from "../../api/fszAPI";
import { getCurrentDate } from "../../utils/dateTimeHelper";
import { UserBuilding, UserBuildingSignUpResults } from "../../Models/User";
import {
  ContentWrapper,
  MiniBlock,
} from "../Main/Main.styled";
import Select from "react-select";
import Swal from "sweetalert2";
import { CheckButton } from "../Shared.styled";

type TimeSelectProps = {
  userInfo: UserBuilding | null;
};

export default function TimeSelect({ userInfo }: TimeSelectProps) {
  const currentTime = getCurrentDate();
  let selectTime = new Date(currentTime);
  //defaultSelectTime.setHours(defaultSelectTime.getHours() + 1);
  const [selectedHour, setSelectedHour] = useState(1);
  const [selectedMinute, setSelectedMinute] = useState(0);
  const hourOptions = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  const minuteOptions = [
    { value: "0", label: "0" },
    { value: "30", label: "30" },
  ];

  const { mutate } = useMutation({
    mutationFn: userBuildingSignUpAPI,
    onSuccess: (data) => {
      if(data.resultState === UserBuildingSignUpResults.Exist)
        window.location.reload();
      else
        {
          Swal.fire({
            title: "설정 실패",
            text: `${data.message}, 설정하신 시간을 확인하시거나 잠시 후 다시 시도해주세요`,
          });
        }
    },
    onError: (error) => {
      Swal.fire({
        title: "설정 실패",
        text: "인터넷 상태를 확인한 후 다시 시도해주세요",
      });
    },
  });

  const onSignUp = () => {
    selectTime.setHours(selectTime.getHours() + selectedHour);
    selectTime.setMinutes(selectTime.getMinutes() + selectedMinute);

    console.log(selectTime);

    if (selectTime > currentTime) {
      mutate({
        buildingID: userInfo?.buildingID ?? 0,
        guid: String(userInfo?.guid),
        startTime: currentTime,
        endTime: selectTime,
      });
    } else {
      Swal.fire({
        title: "설정 실패",
        text: "종료시간은 시작시간 보다 과거일 수 없습니다.",
      });
    }
  };

  return (
    //phoneNumber 제대로 받고있나 log
    <ContentWrapper>
      <>
        <ImageLogo src={BDAppLogo} />
        <Title>체류 예정 시간을 선택해주세요.</Title>
        <TimeWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1.25rem",
              }}
            >
              <SubLabel>현재 시간</SubLabel>
              <Label>{dayjs(currentTime).format("MM월 DD일 HH시 mm분")}</Label>
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#D1D1D1",
                height: "1px",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1.25rem",
              }}
            >
              <SubLabel>선택 시간</SubLabel>
              <Select
                options={hourOptions}
                defaultValue={hourOptions[1]}
                classNamePrefix="select"
                isSearchable={false}
                onChange={(selectedValue) => {
                  setSelectedHour(parseInt(selectedValue?.value ?? "1"));
                }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: "SUITE",
                    width: "60px",
                    fontSize: "1.5rem"
                  }),
                  menuList: (base) => ({
                    ...base,
                    fontSize: "1.5rem",
                    "-ms-overflow-style": "none" /* IE and Edge */,
                    "scrollbar-width": "none" /* Firefox */,
                    "::-webkit-scrollbar": {
                      display: "none" /* Chrome, Safari, Opera*/,
                    },
                  }),
                }}
              />

              <Label>시간</Label>
              <Select
                options={minuteOptions}
                defaultValue={minuteOptions[0]}
                isSearchable={false}
                onChange={(selectedValue) => {
                  setSelectedMinute(parseInt(selectedValue?.value ?? "0"));
                }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    fontFamily: "SUITE",
                    width: "60px",
                    fontSize: "1.5rem"
                  }),
                  menuList: (base) => ({
                    ...base,
                    fontSize: "1.5rem",
                    "-ms-overflow-style": "none" /* IE and Edge */,
                    "scrollbar-width": "none" /* Firefox */,
                    "::-webkit-scrollbar": {
                      display: "none" /* Chrome, Safari, Opera*/,
                    },
                  }),
                }}
              />
              <Label>분</Label>
            </div>
          </div>
        </TimeWrapper>
        <Title>
          선택하신 시간이 종료되면,
          <br />
          안전을 위해 다시 인증해 주세요.
        </Title>
        <MiniBlock />
        <CheckButton onClick={onSignUp}>확인</CheckButton>
      </>
    </ContentWrapper>
  );
}
