import styled from "styled-components";

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 18%;
  height: 150px;
  /* height: 100%; */
  overflow-y: scroll;
  position: relative;

  // For Chrome, Safari and Opera
  ::-webkit-scrollbar {
    display: none;
  }

  // For Firefox
  scrollbar-width: none;
`;

export const ListCenter = styled.div`
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 5px;
  height: 40px;
  position: sticky;
  top: 55px;
  z-index: 5;
`;

export const ListItem = styled.li<{ isSelected: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #555555;
  font-family: "SUITE";
  justify-content: center;
  font-weight: ${({ isSelected }) => isSelected && "bold"};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.4)};
`;
