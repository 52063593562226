import React, { useEffect } from "react";
import Router from "./router/Router";
import GlobalStyle from "./asset/style/GlobalStyle";
import { Reset } from "styled-reset";
import styled from "styled-components";
import Header from "./utils/Header";
import "./locale/i18n";
import { useTranslation } from "react-i18next";
import { useAtomValue, useSetAtom } from "jotai";
import { languageModeAtom } from "./atoms/language";

function App() {
  const { i18n } = useTranslation();
  const setCurrentLanguage = useSetAtom(languageModeAtom);

  useEffect(() => {
    console.log(`i18n`, i18n);
    console.log(`language`, i18n.language);
    console.log(`resolvedLanguage`, i18n.resolvedLanguage);
    setCurrentLanguage(i18n.resolvedLanguage!);
  }, [i18n]);

  return (
    <Container>
      <GlobalStyle />
      <Reset />
      {/* <Header /> */}
      <Router />
    </Container>
  );
}

export default App;

const Container = styled.div`
  /* height: 100dvh; */

  /* width: 100dvw; */
  /* background-color: #d7d6d6; */
  /* margin: auto; */
`;
