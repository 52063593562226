import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import BDAppLogo from "../../asset/images/whitebdapplogo.svg";
import FszLogo from "../../asset/images/whitefszlogo.svg";
import down from "../../asset/images/down.svg";
import send from "../../asset/images/send.svg";
import background from "../../asset/images/alarm.png";
import recoveredBackground from "../../asset/images/disabled.png";
import {
  AlarmHistoryReponse,
  AlarmReactionResponse,
  AlarmReactionResults,
  CommentResults,
  Reaction,
  TotalCommentResponse,
  TotalReactionResponse,
} from "../../Models/Alarm";
import {
  alarmHistoryAPI,
  commentAPI,
  getCommentsAPI,
  getCurrentBuildingInfoAPI,
  getMyLastReactionAPI,
  getReactionsAPI,
  getUserBuildingHistoryAPI,
  reactionAPI,
} from "../../api/fszAPI";
import { UserBuilding, UserBuildingRequestResults } from "../../Models/User";
import { Block, MiniBlock, PhoneNumberLabel } from "../Main/Main.styled";
import { Label } from "../TimeSelect/TimeSelect.styled";
import dayjs from "dayjs";
import { DotLoader } from "react-spinners";
import Swal from "sweetalert2";
import { FszImage, CheckButton } from "../Shared.styled";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { useAtomValue } from "jotai";
import { languageModeAtom } from "../../atoms/language";
import { ENGLISH, KOREAN } from "../../constants/language";

// import { Divider } from '@mui/material';

interface KeyBoardProps {
  keyboardHeight: number;
  windowHeight: number;
}

const path = "pages.alarm";

const Alarm = () => {
  const [alarmHistory, setAlarmHistory] = useState<AlarmHistoryReponse | null>(
    null
  );
  const [reaction, setReaction] = useState<TotalReactionResponse | null>(null);
  const [comment, setComment] = useState<TotalCommentResponse[] | null>(null);
  const [userInfo, setUserInfo] = useState<UserBuilding | null>(null);
  const [lastReaction, setLastReaction] = useState<Reaction | null>(null);
  const [newComment, setNewComment] = useState<string>("");
  const [canRequest, setCanRequest] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHistory, setIsHistory] = useState<boolean>(false);
  const [isFullChat, setIsFullChat] = useState<boolean>(false);
  // const [scrolling, setScrolling] = useState<boolean>(false);
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const focusRef = useRef<HTMLInputElement | null>(null);
  const [visualViewPort, setVisualViewPort] = useState(window.innerHeight);

  const currentLanguage = useAtomValue(languageModeAtom);

  const chatSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setVisualViewPort(
        window.visualViewport
          ? window.visualViewport.height
          : window.innerHeight
      );
    };

    window.addEventListener("resize", handleResize);

    // Initial setting
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isFullChat) {
      document.body.style.height = `${visualViewPort}px`;
    }
  }, [isFullChat, visualViewPort]);

  const { userGuid } = useParams();

  const handleDown = () => {
    setIsFullChat(false);
  };

  const handleFocus = () => {
    setIsFullChat(true);
  };

  const handleLike = async (userInfoParmeter: UserBuilding | null) => {
    if (!canRequest) return;

    setCanRequest(false);

    if (userInfoParmeter && alarmHistory) {
      let reuslt: AlarmReactionResponse | null = null;

      if (lastReaction && lastReaction.like === 1) {
        reuslt = await reactionAPI({
          guid: userInfoParmeter.guid,
          like: 0,
          dislike: 0,
          alarmHistoryID: alarmHistory?.id ?? 0,
        });
      } else {
        reuslt = await reactionAPI({
          guid: userInfoParmeter.guid,
          like: 1,
          dislike: 0,
          alarmHistoryID: alarmHistory?.id ?? 0,
        });
      }

      if (reuslt && reuslt.resultState === AlarmReactionResults.Success) {
        await getMyLastReaction();
        await getReactionData();
      } else {
        Swal.fire({
          title: "반응 등록 실패",
          text: "인터넷 상태를 확인한 후 다시 시도해주세요",
        });
      }
    }
    setCanRequest(true);
  };

  const handleDislike = async (userInfoParmeter: UserBuilding | null) => {
    if (!canRequest) return;

    setCanRequest(false);
    if (userInfoParmeter && alarmHistory) {
      let reuslt: AlarmReactionResponse | null = null;

      if (lastReaction && lastReaction.dislike === 1) {
        reuslt = await reactionAPI({
          guid: userInfoParmeter.guid,
          like: 0,
          dislike: 0,
          alarmHistoryID: alarmHistory?.id ?? 0,
        });
      } else {
        reuslt = await reactionAPI({
          guid: userInfoParmeter.guid,
          like: 0,
          dislike: 1,
          alarmHistoryID: alarmHistory?.id ?? 0,
        });
      }

      if (reuslt && reuslt.resultState === AlarmReactionResults.Success) {
        await getMyLastReaction();
        await getReactionData();
      } else {
        Swal.fire({
          title: "반응 등록 실패",
          text: "인터넷 상태를 확인한 후 다시 시도해주세요",
        });
      }

      setCanRequest(true);
    }
  };

  const handleSubmitComment = async () => {
    if (!canRequest) return;

    setCanRequest(false);

    if (alarmHistory && userInfo) {
      const result = await commentAPI({
        writeNumber: userInfo.buildingID,
        commentContent: newComment,
        topAnchor: "0",
        guid: userInfo.guid,
        alarmHistoryID: alarmHistory.id,
        phoneNumber: userInfo.phoneNumber,
      });

      if (result && result.resultState === CommentResults.Success) {
        setNewComment("");
        await getCommentData();
      } else {
        Swal.fire({
          title: "댓글 등록 실패",
          text: "인터넷 상태를 확인한 후 다시 시도해주세요",
        });
      }
    }

    setCanRequest(true);
  };

  const getUserBuilding = async () => {
    try {
      if (userGuid) {
        const result = await getCurrentBuildingInfoAPI(userGuid);

        if (result.resultState === UserBuildingRequestResults.Success)
          setUserInfo(result.userBuilding);
        else {
          const userHistory = await getUserBuildingHistoryAPI(userGuid);
          setIsHistory(true);
          if (userHistory) setUserInfo(userHistory.userBuildingHistory);
        }
      } else {
      }
    } catch (error) {}
  };

  const getAlarmHistory = async () => {
    try {
      if (userGuid) {
        const result = await alarmHistoryAPI(userGuid);
        setAlarmHistory(result);

        return result;
      } else {
      }
    } catch (error) {}
  };

  const getMyLastReaction = async () => {
    try {
      if (userInfo) {
        const result = await getMyLastReactionAPI({
          alarmHistoryID: alarmHistory?.id ?? 0,
          guid: userInfo.guid,
        });

        setLastReaction(result);
      }
    } catch (error) {}
  };

  const getReactionData = async () => {
    try {
      if (alarmHistory) {
        const result = await getReactionsAPI(alarmHistory?.id ?? 0);
        setReaction(result);
      }
    } catch (error) {}
  };

  const getCommentData = async () => {
    try {
      if (alarmHistory) {
        const result = await getCommentsAPI(alarmHistory?.id ?? 0);
        setComment(result);
        messageEndRef.current?.scrollTo(0, 9999999);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (comment) {
      messageEndRef.current?.scrollTo(0, 9999999);
    }
  }, [comment]);

  useEffect(() => {
    // messageEndRef.current?.addEventListener("scroll", (e) => {
    //   setScrolling(true);
    // });
    // messageEndRef.current?.addEventListener("scrollend", (e) => {
    //   console.log(false)
    //   setScrolling(false);
    // });
    // console.log(1);
    // messageEndRef.current

    setIsLoading(true);
    getUserBuilding()
      .then(async () => {
        await getAlarmHistory();
      })
      .then(() => {
        const timer = setInterval(async () => {
          await getAlarmHistory();
          await getCommentData();
        }, 8000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getMyLastReaction();
    getReactionData();
    getCommentData();
  }, [alarmHistory]);

  return (
    <>
      <>
        {isLoading ? (
          <DotLoader />
        ) : alarmHistory ? (
          <>
            <Wrapper $isFullChat={isFullChat} $visualViewport={visualViewPort}>
              <LogoContainer>
                <LogoImage src={BDAppLogo} />
                <ServiceLogoImage src={FszLogo} />
              </LogoContainer>
              <AlarmInfoSection>
                <AlarmInfoContainer>
                  <h2>{alarmHistory.buildingName}</h2>
                  <h1>{alarmHistory.location}</h1>
                  <div>
                    {alarmHistory.endTime ? (
                      <>
                        {/* <span>화재 복구</span> */}
                        <span>
                          {new Date(alarmHistory.startTime).toLocaleString(
                            currentLanguage === KOREAN
                              ? "ko-KR"
                              : currentLanguage === ENGLISH
                              ? "en-US"
                              : "vi-VI"
                          )}
                          <br />
                          <Trans i18nKey={`${path}.occur`} />
                        </span>
                        <span>
                          {new Date(alarmHistory.endTime).toLocaleString(
                            currentLanguage === KOREAN
                              ? "ko-KR"
                              : currentLanguage === ENGLISH
                              ? "en-US"
                              : "vi-VI"
                          )}
                          <br />
                          <Trans i18nKey={`${path}.resetCompleted`} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          {new Date(alarmHistory.startTime).toLocaleString(
                            currentLanguage === KOREAN
                              ? "ko-KR"
                              : currentLanguage === ENGLISH
                              ? "en-US"
                              : "vi-VI"
                          )}
                          <br />
                          <Trans i18nKey={`${path}.detect`} />
                        </span>
                      </>
                    )}
                  </div>
                </AlarmInfoContainer>
              </AlarmInfoSection>
              <SelectReactionSection>
                <span>
                  <Trans i18nKey={`${path}.vote`} />
                </span>
                <ButtonBox>
                  <FireFalseButton
                    isDisabled={alarmHistory.endTime != null}
                    disabled={isHistory || alarmHistory.endTime != null}
                    onClick={() => handleDislike(userInfo)}
                    isSelected={lastReaction?.dislike === 1}
                  >
                    <span>
                      <Trans i18nKey={`${path}.fireFalse`} />
                    </span>
                    <div />
                    <span>{reaction ? reaction.totalDislike : 0}</span>
                  </FireFalseButton>
                  <FireTrueButton
                    disabled={isHistory || alarmHistory.endTime != null}
                    isDisabled={alarmHistory.endTime != null}
                    onClick={() => handleLike(userInfo)}
                    isSelected={lastReaction?.like === 1}
                  >
                    <span>
                      <Trans i18nKey={`${path}.fireTrue`} />
                    </span>
                    <div></div>
                    <span>{reaction ? reaction?.totalLike : 0}</span>
                  </FireTrueButton>
                </ButtonBox>
              </SelectReactionSection>
              <ChatSection
                ref={chatSectionRef}
                $hasComment={!!comment}
                $noChatMessage={t(`${path}.noChat`)}
                $isFullChat={isFullChat}
                $visualViewPort={visualViewPort}
                className="chatSection"
              >
                <ChatListBox className="chatListBox">
                  {isFullChat && (
                    <ChatDownArrow src={down} onClick={handleDown} />
                  )}
                  {comment && (
                    <CommentsList className="commentList" ref={messageEndRef}>
                      {comment?.map((comment, index) =>
                        comment.guid === userGuid ? (
                          <MyCommentContentContainer key={index}>
                            <CommentPhone style={{ alignSelf: "end" }}>
                              {comment.userNumber}
                            </CommentPhone>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "end",
                                marginLeft: "0.25rem",
                                marginTop: "0.25rem",
                                marginBottom: "0.75rem",
                              }}
                            >
                              <MyCommentTime>
                                {dayjs(comment.startTime).format("A hh:mm")}
                              </MyCommentTime>
                              <MyCommentText>
                                {comment.commentContent}
                              </MyCommentText>
                            </div>
                          </MyCommentContentContainer>
                        ) : (
                          <CommentContentContainer key={index}>
                            <CommentPhone>{comment.userNumber}</CommentPhone>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "start",
                                marginLeft: "0.25rem",
                                marginTop: "0.25rem",
                                marginBottom: "0.75rem",
                              }}
                            >
                              <CommentText>
                                {comment.commentContent}
                              </CommentText>
                              <CommentTime>
                                {dayjs(comment.startTime).format("A hh:mm")}
                              </CommentTime>
                            </div>
                          </CommentContentContainer>
                        )
                      )}
                    </CommentsList>
                  )}
                </ChatListBox>
                <ChatInput
                  ref={focusRef}
                  onFocus={handleFocus}
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder={t(`${path}.chatInputPlaceholder`)}
                />
                {isFullChat && (
                  <SendImage
                    src={send}
                    onClick={(e) => {
                      e.preventDefault();
                      focusRef.current?.focus();
                      handleSubmitComment();
                    }}
                  />
                )}
              </ChatSection>
            </Wrapper>
          </>
        ) : (
          <></>
        )}
      </>
      <>
        {/* {!isLoading ? (
          alarmHistory ? (
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <div
                style={{
                  position: "relative",
                  zIndex: isFullChat ? "1" : "2",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "55%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: "column",
                    width: "100%",
                    height: "75%",
                    alignItems: "center",
                    backgroundImage:
                      "linear-gradient(186deg, #EC435A 1.49%, #ED6057 100.71%)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "10%",
                      width: "100%",
                    }}
                  >
                    <FszImage
                      src={BDAppLogo}
                      style={{
                        height: "100%",
                        width: "15%",
                        marginLeft: "1rem",
                        marginTop: "0.25rem",
                      }}
                    />
                    <div style={{ width: "50%" }}></div>
                    <FszImage
                      src={FszLogo}
                      style={{
                        height: "100%",
                        width: "30%",
                        justifySelf: "end",
                        alignSelf: "start",
                        marginRight: "1rem",
                        marginTop: "0.25rem",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "start",
                      height: "65%",
                      width: "100%",
                    }}
                  >
                    <AlarmLabel
                      style={{
                        marginBottom: "3rem",
                        marginTop: "3rem",
                        fontSize: "1.6rem",
                      }}
                    >
                      {alarmHistory.buildingName}
                    </AlarmLabel>
                    <AlarmTitleLabel style={{ marginBottom: "2rem" }}>
                      {alarmHistory.location}
                    </AlarmTitleLabel>
                    {alarmHistory.endTime ? (
                      <>
                        <AlarmLabel>화재 복구</AlarmLabel>
                        <AlarmLabel>
                          ({new Date(alarmHistory.startTime).toLocaleString()}{" "}
                          화재 신호 발생)
                        </AlarmLabel>
                        <AlarmLabel>
                          ({new Date(alarmHistory.endTime).toLocaleString()}{" "}
                          화재 복구 완료)
                        </AlarmLabel>
                      </>
                    ) : (
                      <>
                        <AlarmLabel>
                          {new Date(alarmHistory.startTime).toLocaleString()}{" "}
                          <br /> 화재감지
                        </AlarmLabel>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: "25%",
                    width: "100%",
                    flexDirection: "column",
                    backgroundColor: "#54363A",
                  }}
                >
                  <AlarmLabel
                    style={{
                      marginTop: "0.5rem",
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: "#ABABAB",
                    }}
                  >
                    현장을 목격하신 분들 중 실제 화재인지
                    <br />
                    아닌지 아래 버튼으로 알려주세요!
                  </AlarmLabel>
                  <ReactionContainer>
                    <DislikeReactionButton
                      isDisabled={alarmHistory.endTime != null}
                      disabled={isHistory || alarmHistory.endTime != null}
                      onClick={() => handleDislike(userInfo)}
                      isSelected={lastReaction?.dislike === 1}
                    >
                      <ReactonButtonLabel style={{ height: "50%" }}>
                        화재 아니예요!
                      </ReactonButtonLabel>
                      <div
                        style={{
                          width: "90%",
                          backgroundColor: "#D1D1D1",
                          height: "1px",
                          alignSelf: "center",
                          justifySelf: "center",
                        }}
                      />
                      <ReactonButtonLabel style={{ height: "50%" }}>
                        {reaction ? reaction.totalDislike : 0}
                      </ReactonButtonLabel>
                    </DislikeReactionButton>
                    <LikeReactionButton
                      disabled={isHistory || alarmHistory.endTime != null}
                      isDisabled={alarmHistory.endTime != null}
                      onClick={() => handleLike(userInfo)}
                      isSelected={lastReaction?.like === 1}
                    >
                      <ReactonButtonLabel style={{ height: "50%" }}>
                        실제 화재 같아요!
                      </ReactonButtonLabel>
                      <div
                        style={{
                          width: "90%",
                          backgroundColor: "#D1D1D1",
                          height: "1px",
                          alignSelf: "center",
                          justifySelf: "center",
                        }}
                      />
                      <ReactonButtonLabel style={{ height: "50%" }}>
                        {reaction ? reaction?.totalLike : 0}
                      </ReactonButtonLabel>
                    </LikeReactionButton>
                  </ReactionContainer>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  zIndex: isFullChat ? "2" : "1",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: isFullChat ? "95%" : "45%",
                  overflow: "hidden",
                  justifySelf: "end",
                  alignSelf: "end",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "rgba(151, 126, 121, 0.95)",
                  }}
                >
                  <CommentContainer onClick={handleFocus}>
                    {comment ? (
                      <CommentsList className="commentList" ref={messageEndRef}>
                        {comment?.map((comment, index) =>
                          comment.guid === userGuid ? (
                            <MyCommentContentContainer key={index}>
                              <CommentPhone style={{ alignSelf: "end" }}>
                                {comment.userNumber}
                              </CommentPhone>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "end",
                                  marginLeft: "0.25rem",
                                  marginTop: "0.25rem",
                                  marginBottom: "0.75rem",
                                }}
                              >
                                <MyCommentTime>
                                  {dayjs(comment.startTime).format("A hh:mm")}
                                </MyCommentTime>
                                <MyCommentText>
                                  {comment.commentContent}
                                </MyCommentText>
                              </div>
                            </MyCommentContentContainer>
                          ) : (
                            <CommentContentContainer key={index}>
                              <CommentPhone>{comment.userNumber}</CommentPhone>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "start",
                                  marginLeft: "0.25rem",
                                  marginTop: "0.25rem",
                                  marginBottom: "0.75rem",
                                }}
                              >
                                <CommentText>
                                  {comment.commentContent}
                                </CommentText>
                                <CommentTime>
                                  {dayjs(comment.startTime).format("A hh:mm")}
                                </CommentTime>
                              </div>
                            </CommentContentContainer>
                          )
                        )}
                      </CommentsList>
                    ) : (
                      <>
                        <AlarmLabel style={{ alignSelf: "center" }}>
                          아직 댓글이 없습니다
                        </AlarmLabel>
                      </>
                    )}
                    <CommentSection>
                      <CommentInput
                        style={{ height: "100%" }}
                        disabled={isHistory || alarmHistory.endTime != null}
                        value={newComment}
                        onFocus={handleFocus}
                        ref={focusRef}
                        onChange={(e) => {
                          setNewComment(e.target.value);
                        }}
                      />
                      <FszImage
                        src={send}
                        style={{ height: "100%" }}
                        onClick={(e) => {
                          e.preventDefault();
                          focusRef.current?.focus();
                          handleSubmitComment();
                        }}
                      ></FszImage>
                    </CommentSection>
                  </CommentContainer>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  zIndex: "3",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "10%",

                  alignItems: "center",
                }}
              >
                {isFullChat ? (
                  <>
                    <div style={{ width: "100%", height: "50%" }} />
                    <div style={{ width: "100%", height: "50%" }}>
                      <FszImage
                        src={down}
                        style={{ height: "15px", marginTop: "0.25rem" }}
                        onClick={handleDown}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )
        ) : (
          <DotLoader />
        )} */}
      </>
    </>
  );
};

const Wrapper = styled.div<{ $isFullChat: boolean; $visualViewport: number }>`
  /* height: ${({ $visualViewport }) => `${$visualViewport}px`};
  position: fixed;
  top: 0;
  overflow-y: hidden; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $visualViewport }) => `${$visualViewport}px`};
  ${(props) =>
    props.$isFullChat &&
    css`
      position: fixed;
      top: 0;
      overflow-y: hidden;
    `}
`;

const AlarmInfoSection = styled.section`
  height: 45vh; // dvh지원하지 않는 브라우저에서 45vh로 적용하기 위함, 지원하지 않는 css는 무시하기 때문
  height: 45dvh;
  background: linear-gradient(186deg, #ec435a 1.49%, #ed6057 100.71%);
`;

const LogoContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 3rem;
`;

const LogoImage = styled.img`
  position: absolute;
  height: 1rem;
  left: 1rem;
  top: 1rem;
`;

const ServiceLogoImage = styled.img`
  position: absolute;
  height: 1rem;
  right: 1rem;
  top: 1rem;
`;

const AlarmInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 1rem;
  padding-top: 3rem;

  color: white;
  font-family: "SUITE";
  font-weight: 800;

  h2 {
    font-size: 1.5rem;
  }
  h1 {
    font-size: 2rem;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  span {
    font-size: 1.2rem;
  }
`;

const SelectReactionSection = styled.section`
  /* height: 20dvh; */
  background-color: rgba(84, 54, 58, 1);
  padding: 0.5rem 1rem;

  display: flex;
  flex-direction: column;

  & > span {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    /* height: 50%; */
    color: white;
    font-family: "SUITE";
    font-weight: 500;
    line-height: 1.25rem;

    word-wrap: break-word;
    word-break: keep-all;

    display: inline-block;
    margin-bottom: 0.25rem;
  }
`;

const ButtonBox = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  /* height: 50%; */
  display: flex;
  justify-content: center;
  font-family: "SUITE";
`;

const FireFalseButton = styled.button<ReactionButtonProps>`
  width: 50%;
  margin-right: 0.25rem;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-width: 1.5px;
  border-style: solid;
  /* padding-top: 1rem;
  padding-bottom: 1rem; */

  border-color: ${(props) => (props.isSelected ? "#ffffff" : "transparent")};
  background-color: ${(props) =>
    props.isDisabled ? "gray" : props.isSelected ? "#71434E" : "#835B64;"};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    width: 80%;
    height: 1px;
    border-bottom: 2px solid rgba(230, 230, 230, 1);
  }

  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "SUITE";
  span {
    letter-spacing: -0.08rem;
  }
`;

const FireTrueButton = styled.button<ReactionButtonProps>`
  width: 50%;
  margin-left: 0.25rem;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-width: 1.5px;
  border-style: solid;
  /* padding-top: 1rem;
  padding-bottom: 1rem; */

  border-color: ${(props) => (props.isSelected ? "#ffffff" : "transparent")};
  background-color: ${(props) =>
    props.isDisabled ? "gray" : props.isSelected ? "#F64242" : "#FF6F6F"};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    width: 80%;
    height: 1px;
    border-bottom: 2px solid rgba(230, 230, 230, 1);
  }

  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "SUITE";
  span {
    letter-spacing: -0.08rem;
  }
`;

const ChatSection = styled.section<{
  $hasComment: boolean;
  $noChatMessage: string;
  $isFullChat?: boolean;
  $visualViewPort: number;
}>`
  width: 100%;
  flex-grow: 1;
  position: relative;
  ${({ $isFullChat, $visualViewPort }) =>
    $isFullChat
      ? css`
          border-top: 3px solid #563838;
          position: fixed;
          left: 0;
          right: 0;
          top: 3rem;
          height: calc(${$visualViewPort}px - 3rem);
          z-index: 10;

          overflow: hidden;
        `
      : css`
          height: 35vh;
          height: 35dvh;
        `}

  background-color: rgba(146, 119, 113, 0.95);

  scroll-behavior: none;

  ${(props) =>
    !props.$hasComment &&
    css`
      &::after {
        content: "${props.$noChatMessage}";
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -40%);
        color: rgba(255, 255, 255, 0.8);
        font-family: "SUITE";
        font-size: 0.9rem;
      }
    `}
`;

const ChatListBox = styled.div`
  width: 100%;
  height: calc(100% - 3rem);
  padding: 0 1rem;

  position: relative;
`;

const ChatDownArrow = styled.img`
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translateX(-50%);
`;

const ChatInput = styled.input`
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */
  width: 100%;

  border: none;
  height: 3rem;
  outline: none;

  padding: 0;

  font-family: "SUITE";
  text-align: left;
  font-size: 1.2rem;
  padding: 0 1rem;
`;
const SendImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;

  height: calc(3rem + 0.4px);
`;

interface ReactionButtonProps {
  isSelected: boolean;
  isDisabled: boolean;
}

const LikeReactionButton = styled(CheckButton)<ReactionButtonProps>`
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 90%;
  margin-left: 0.25rem;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-width: 1.5px;
  border-style: solid;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  align-items: center;
  justify-content: center;
  border-color: ${(props) => (props.isSelected ? "#ffffff" : "transparent")};
  background-color: ${(props) =>
    props.isDisabled ? "gray" : props.isSelected ? "#F64242" : "#FF6F6F"};
`;
const DislikeReactionButton = styled(CheckButton)<ReactionButtonProps>`
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 90%;
  margin-right: 0.25rem;
  border-radius: 10px;
  border-width: 1.5px;
  border-style: solid;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.25);

  // border: 2px solid #fff;
  border-color: ${(props) => (props.isSelected ? "#ffffff" : "transparent")};
  background-color: ${(props) =>
    props.isDisabled ? "gray" : props.isSelected ? "#71434E" : "#835B64;"};
`;

// ${props => (props.windowHeight)};

const AlarmTitleLabel = styled(Label)`
  font-size: 2.5rem;
  font-weight: 800;
  color: white;
  word-break: keep-all;
  line-height: 1.25;
`;

const AlarmLabel = styled(Label)`
  color: white;
  font-size: 1rem;
  font-weight: 800;
`;

const ReactonButtonLabel = styled(AlarmLabel)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReactionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 100%;
  height: 90%;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  justify-self: end;
  align-items: center;
  width: 100%;
  height: 100%;
  border-top: 2px solid #563838;
`;

const CommentSection = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: end;
`;

const CommentInput = styled.input`
  flex-grow: 1;
  align-items: start;
  text-align: left;
  font-family: "SUITE";
  font-size: 1.25rem;
  width: 80%;
  border: none;
  padding-left: 0.75rem;
`;

const CommentsList = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-size: 1rem;
  font-family: "SUITE";
  padding: 0.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const CommentContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
`;

const CommentPhone = styled.span`
  align-self: start;
  margin-left: 0.25rem;
  font-size: 0.75rem;
  color: white;
`;

const CommentText = styled.span`
  background-color: white;
  font-size: 1.15rem;
  color: black;
  align-self: start;
  border-radius: 5px;
  font-family: "SUITE";
  padding: 0.25rem;
  display: flex;
  word-break: keep-all;
  max-width: 90%;
`;

const CommentTime = styled.span`
  color: white;
  font-size: 0.6rem;
  align-self: end;
  margin-left: 0.25rem;
`;

const MyCommentContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
`;

const MyCommentText = styled.span`
  background-color: #fffcaf;
  font-size: 1rem;
  color: black;
  align-self: start;
  border-radius: 5px;
  font-family: "SUITE";
  padding: 0.35rem;
  display: flex;
  word-break: keep-all;
  word-wrap: break-word;
`;

const MyCommentTime = styled.span`
  color: white;
  font-size: 0.6rem;
  align-self: end;
  margin-right: 0.25rem;
`;

export default Alarm;
