import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import logoImage from "../../asset/images/logo-horizontal.svg";
import styled from "styled-components";
import {
  addBuildingUser,
  deleteBuildingUser,
  getBuildingUserCount,
  getBuildingUsers,
  importBuildingUser,
  updateBuildingUser,
} from "../../api/fszAPI";
import { User } from "../../Models/User";
import RemoveConfirmModal from "./components/Modal/RemoveConfirmModal";
import { Pagination } from "@mui/material";
import AddUserCheckModal from "./components/Modal/AddUserCheckModal";
import Footer from "../../components/layout/Footer";
import userManager, { onLogout } from "../../auth/AuthManager";

const numberOfUsersPerPage = 15;

const formatPhoneNumber = (value: string) => {
  if (value.length <= 3) {
    return value;
  } else if (value.length <= 6) {
    return `${value.slice(0, 3)} ${value.slice(3)}`;
  } else if (value.length <= 10) {
    return `${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
  } else {
    return `${value.slice(0, 3)} ${value.slice(3, 7)} ${value.slice(7)}`;
  }
};

const AdminUser = () => {
  /* 전체 사용자 */
  const [users, setUsers] = useState<User[]>([]);
  /* 사용자 다중 선택을 위한 Set 타입의 selectedUsers state */
  const [selectedUsers, setSelectedUsers] = useState<Set<number>>(new Set());
  /* 수정 대상이 되는 targetUser, 한번에 한명만 업데이트할 수 있다.(2명 이상 선택하는 경우 수정 버튼 사라짐) */
  const [targetUser, setTargetUser] = useState<User>();

  /* 신규 사용자 추가하는 input 값을 관리하는 state */
  const [buildingNumber, setBuildingNumber] = useState("");
  const [unit, setUnit] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  /* 유저 추가, 선택할 경우를 감지하는 mode state */
  const [addUserMode, setAddUserMode] = useState<boolean>(false);
  const [selectMode, setSelectMode] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  /* 페이지 관리하는 state */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [isRemoveUserConfirmModalOpen, setIsRemoveUserConfirmModalOpen] =
    useState(false);
  const [isAddUserCheckModalOpen, setIsAddUserCheckModalOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const importFileRef = useRef<HTMLInputElement>(null);

  const param = useParams();
  const buildingId = Number(param.buildingId);

  const onChangeNewUserBuildingNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBuildingNumber(e.target.value);
  };
  const onChangeNewUserUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnit(e.target.value);
  };
  const onChangeNewUserPhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, ""); // 숫자만 남기기

    if (numericValue.length <= 11) {
      setPhoneNumber(numericValue);
    }
  };
  /* 유저 추가 */
  const handleAddUser = async () => {
    if (!buildingNumber || !unit || phoneNumber.length <= 10) {
      setIsAddUserCheckModalOpen((prev) => !prev);
      return;
    }
    const requestParam = {
      buildingId,
      phoneNumber: phoneNumber,
      buildingNumber,
      unit,
    };
    try {
      await addBuildingUser(requestParam);
      await getUserCountAndSetPage();
      await getUsers(currentPage);
      setAddUserMode(false);
      setBuildingNumber("");
      setUnit("");
      setPhoneNumber("");
    } catch (error) {
      console.log(error);
    }
  };

  const onClickRemoveModal = () => {
    setIsRemoveUserConfirmModalOpen(true);
  };
  /**
   * 유저 삭제
   * selectedUsers 에서 true인 key값을 배열로 만들고,
   * Promise.all 을 통해 병렬적으로 삭제
   */
  const handleRemoveUsers = async () => {
    try {
      const result = await Promise.all(
        [...selectedUsers].map((id) => {
          const requestData = {
            buildingId: buildingId,
            userId: id,
          };
          return deleteBuildingUser(requestData);
        })
      );
      await getUserCountAndSetPage();
      await getUsers(currentPage);
      console.log(`사용자 삭제 요청 성공`, result);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 선택된 id로 유저를 필터링
   */
  const onClickEditButton = () => {
    setEditMode(true);
    const target = users.filter((user) => user.id === [...selectedUsers][0])[0];
    setTargetUser(target);
    // setUsers(editUsers);
  };
  const handleEditTargetBuildingNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTargetUser((user) => ({
      ...(user as User),
      buildingNumber: e.target.value,
    }));
  };
  const handleEditTargetUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetUser((user) => ({
      ...(user as User),
      unit: e.target.value,
    }));
  };
  const handleEditTargetPhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, ""); // 숫자만 남기기

    if (numericValue.length <= 11) {
      setTargetUser((user) => ({
        ...(user as User),
        phoneNumber: numericValue,
      }));
    }
  };
  const handleUpdateUsers = async () => {
    if (
      !targetUser!.buildingNumber ||
      !targetUser!.unit ||
      targetUser!.phoneNumber.length <= 10
    ) {
      setIsAddUserCheckModalOpen((prev) => !prev);
      return;
    }
    try {
      const result = await updateBuildingUser(targetUser as User);
      console.log(`유저 정보 업데이트 결과`, result);
      await getUserCountAndSetPage();
      await getUsers(currentPage);
      setEditMode(false);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * 선택된 id값의 유저를 선택여부를 true/false로 변경
   * @param id
   */
  const handleSelectUser = (id: number) => {
    setSelectedUsers((prevSelectedUsers) => {
      const newSelectedUsers = new Set(selectedUsers);
      if (prevSelectedUsers.has(id)) {
        newSelectedUsers.delete(id);
      } else {
        newSelectedUsers.add(id);
      }
      return newSelectedUsers;
    });
  };

  /* 최초 렌더링 시 유저 불러오기(페이지네이션 필요) */
  const getUsers = async (currentPage: number) => {
    try {
      const response = await getBuildingUsers(
        buildingId,
        15 * (currentPage - 1),
        numberOfUsersPerPage
      );
      setUsers(response.users);
    } catch (err) {
      console.log(err);
    }
  };
  const getUserCountAndSetPage = async () => {
    try {
      const response = await getBuildingUserCount(buildingId);
      const userCount = response.count;
      const isDivisible =
        Math.floor(userCount / numberOfUsersPerPage) ===
        userCount / numberOfUsersPerPage;
      let pageCount = isDivisible
        ? Math.floor(userCount / numberOfUsersPerPage)
        : Math.floor(userCount / numberOfUsersPerPage) + 1;
      pageCount = !pageCount ? 1 : pageCount;
      setTotalPages(pageCount);
      setCurrentPage((prevPage) =>
        prevPage >= pageCount ? pageCount : prevPage
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const resetPage = async () => {
      await getUserCountAndSetPage();
      await getUsers(currentPage);
    };
    resetPage();
  }, [currentPage]);

  /* 페이지가 다시 호출되거나 */
  useEffect(() => {
    if (users) {
      const newSelectedUsers = new Set<number>();
      users.forEach((user) => {
        if (selectedUsers.has(user.id)) {
          newSelectedUsers.add(user.id);
        }
      });
      setSelectedUsers(newSelectedUsers);
    }
  }, [users]);

  useEffect(() => {
    const isSomeSelected = !!selectedUsers.size;
    // 선택 유저가 있는지에 따라 자동으로 selectMode가 on/off된다.
    setSelectMode(isSomeSelected);
  }, [selectedUsers]);

  const handleCancelEdit = () => {
    setEditMode(false);
    setTargetUser(undefined);
  };

  const onClickImportFile = () => {
    if (importFileRef.current) {
      importFileRef.current.click();
    }
  };

  const onClickLogout = () => {
    onLogout();
  }

  const handleImportFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (importFileRef.current?.files) {
        const file = importFileRef.current.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("buildingId", String(buildingId));
        const result = await importBuildingUser(formData);
        await getUserCountAndSetPage();
        await getUsers(currentPage);
        console.log(`파일 불러오기 성공 결과`, result);
        importFileRef.current.value = "";
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <PageContainer>
      <HeaderContainer>
        <Header>
          <img src={logoImage} alt="logoImage" />
          <AuthButton onClick={onClickLogout}>로그아웃</AuthButton>
        </Header>
        <PageTitleSection>
          <PageTitle>입주자 리스트</PageTitle>
          <PageTitleEng>Fire Safe Zone</PageTitleEng>
        </PageTitleSection>
      </HeaderContainer>
      <ContentContainer>
        <ContentWrap>
          {/* 유저 정보 테이블의 첫행(제목) */}
          <ContentRowContainer>
            <ContentTitle $color="#A4A7AF" $flex="1.2">
              번호
            </ContentTitle>
            <ContentTitle $color="#A4A7AF" $flex="1.9">
              동
            </ContentTitle>
            <ContentTitle $color="#A4A7AF" $flex="1.9">
              호수
            </ContentTitle>
            <ContentTitle $color="#A4A7AF" $flex="3.5">
              연락처
            </ContentTitle>
          </ContentRowContainer>

          {/* 유저 정보 나열 */}
          {users?.map((user, index) => (
            <ContentRowContainer
              key={index}
              onClick={() => {
                if (!editMode) {
                  handleSelectUser(user.id);
                }
              }}
              $isSelected={selectedUsers.has(user.id)}
            >
              <Content $color="#A4A7AF" $flex="1.2">
                {numberOfUsersPerPage * (currentPage - 1) + index + 1}
              </Content>
              <Content $flex="1.9">
                {editMode && user.id === targetUser!.id ? (
                  <Input
                    type="text"
                    placeholder="동 입력"
                    value={targetUser!.buildingNumber}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleEditTargetBuildingNumber(e)}
                  />
                ) : (
                  user.buildingNumber
                )}
              </Content>
              <Content $flex="1.9">
                {editMode && user.id === targetUser!.id ? (
                  <Input
                    type="text"
                    placeholder="호수 입력"
                    value={targetUser!.unit}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleEditTargetUnit(e)}
                  />
                ) : (
                  user.unit
                )}
              </Content>
              <Content $flex="3.5">
                {editMode && user.id === targetUser!.id ? (
                  <Input
                    type="text"
                    placeholder="연락처 입력"
                    value={formatPhoneNumber(targetUser!.phoneNumber)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleEditTargetPhoneNumber(e)}
                  />
                ) : (
                  formatPhoneNumber(user.phoneNumber)
                )}
              </Content>
            </ContentRowContainer>
          ))}

          {/* 추가버튼 클릭시 렌더링되는 유저 추가 input */}
          {addUserMode && (
            <ContentRowContainer>
              <Content $color="#A4A7AF" $flex="1.2"></Content>
              <Content $color="#A4A7AF" $flex="1.9">
                <Input
                  type="text"
                  placeholder="동"
                  value={buildingNumber}
                  onChange={onChangeNewUserBuildingNumber}
                />
              </Content>
              <Content $color="#A4A7AF" $flex="1.9">
                <Input
                  type="text"
                  placeholder="호수"
                  value={unit}
                  onChange={onChangeNewUserUnit}
                />
              </Content>
              <Content $color="#A4A7AF" $flex="3.5">
                <Input
                  type="text"
                  placeholder="연락처"
                  value={formatPhoneNumber(phoneNumber)}
                  onChange={onChangeNewUserPhoneNumber}
                />
              </Content>
            </ContentRowContainer>
          )}
        </ContentWrap>

        <Pagination
          style={{ margin: "0px auto" }}
          size={windowWidth > 600 ? "large" : "small"}
          page={currentPage}
          count={totalPages}
          onChange={(_, page) => setCurrentPage(page)}
        />

        <ButtonContainer>
          {!selectMode && (
            <>
              <Button
                onClick={() => {
                  setAddUserMode((prev) => !prev);
                  setBuildingNumber("");
                  setUnit("");
                  setPhoneNumber("");
                }}
              >
                {!addUserMode ? "추가" : "취소"}
              </Button>
              {!addUserMode && (
                <>
                  <Button onClick={onClickImportFile}>불러오기</Button>
                  <input
                    ref={importFileRef}
                    type="file"
                    id="importFileInput"
                    style={{ display: "none" }}
                    onChange={handleImportFile}
                  />
                </>
              )}
              {addUserMode && <Button onClick={handleAddUser}>저장</Button>}
            </>
          )}
          {selectMode && (
            <>
              {!editMode && (
                <>
                  <Button onClick={onClickRemoveModal}>삭제</Button>
                  {selectedUsers.size === 1 && (
                    <Button onClick={onClickEditButton}>수정</Button>
                  )}
                </>
              )}
              {editMode && (
                <>
                  <Button onClick={handleCancelEdit}>취소</Button>
                  <Button onClick={handleUpdateUsers}>저장</Button>
                </>
              )}
            </>
          )}
        </ButtonContainer>

        {/* 유저 선택 후 삭제 버튼을 누르면 나타나는 모달 */}
        {isRemoveUserConfirmModalOpen && (
          <RemoveConfirmModal
            setIsRemoveUserConfirmModalOpen={setIsRemoveUserConfirmModalOpen}
            handleRemoveUsers={handleRemoveUsers}
          />
        )}
        {/* 유저 추가할 때 input 입력이 안되면 나타나는 모달 */}
        {isAddUserCheckModalOpen && (
          <AddUserCheckModal
            setIsAddUserCheckModalOpen={setIsAddUserCheckModalOpen}
          />
        )}
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};

export default AdminUser;

const PageContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;

  /* ------------- */
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  width: 100%;
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  flex: 1;
`;

const Header = styled.header`
  width: 100%;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AuthButton = styled.button`
  width: 120px;
  height: 100%;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid #ff5959;
  background: linear-gradient(180deg, #ff8181 0%, #ec4b58 100%);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.25);

  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.5px;

  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
  }
`;

const PageTitleSection = styled.section`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  background: linear-gradient(90deg, #ec4459 -0.06%, #ed5e57 99.24%);

  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`;

const PageTitle = styled.h1`
  color: #fff;
  font-family: Pretendard;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

const PageTitleEng = styled.h1`
  font-family: "The Jamsil OTF";
  font-size: 30px;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;

  background: linear-gradient(
    80deg,
    rgba(255, 190, 169, 0.5) -17.77%,
    rgba(255, 191, 200, 0.5) 110.61%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

const ContentWrap = styled.section`
  width: 100%;
  padding: 20px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* gap: 20px; */
`;

const ContentRowContainer = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;

  width: 100%;
  height: 50px;
  margin: 0 auto;
  max-width: 1200px;

  background-color: ${({ $isSelected }) =>
    $isSelected && "rgba(255, 223, 223, 1)"};

  @media screen and (max-width: 600px) {
    gap: 5px;
    padding: 0px 5px;
  }
`;

const Content = styled.span<{ $color?: string; $flex: string }>`
  width: 100%;
  height: 100%;
  color: ${({ $color }) => $color};
  flex: ${({ $flex }) => $flex};

  font-family: Pretendard;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #e8e8ea;
  /* padding: 10px 0px; */

  @media screen and (max-width: 600px) {
    font-size: 1rem;
  }
`;

const ContentTitle = styled(Content)`
  border-bottom: 2px solid #a4a7af;
`;

const ButtonContainer = styled.div`
  padding: 20px 0px;
  margin: 0px auto;
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Button = styled.button`
  display: flex;
  width: 180px;
  height: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid #ff5959;
  background: linear-gradient(180deg, #ff8181 0%, #ec4b58 100%);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.25);

  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.5px;

  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 7rem;
    height: 3rem;
    font-size: 1rem;
  }
`;

const Input = styled.input`
  justify-self: center;
  width: 90%;
  height: 90%;
  /* padding: 10px; */
  margin: 5px 0;

  border-radius: 5px;
  border: 2px solid #ff6c6c;

  font-size: 25px;
  font-weight: 600;
  font-family: Pretendard;

  @media screen and (max-width: 600px) {
    font-size: 1rem;
    padding: 0;
  }
`;
