import React, { useEffect, useState } from "react";
import { ImageLogo, Message } from "./Success.styled";
//import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Block, ContentWrapper, PhoneNumberLabel } from "../Main/Main.styled";
import { FszImage } from "../Shared.styled";
import BDAppLogo from "../../asset/images/bdappiconhori.svg";
import MainBackground from "../../asset/images/mainbackground.svg";
import MainWhiteBackground from "../../asset/images/mainwhitebackground.svg";
import FszIcon from "../../asset/images/fszicon.svg";
import City from "../../asset/images/city.svg";
import SuccessIcon from "../../asset/images/messagesuccess.svg";
import styled from "styled-components";
import { useAtomValue } from "jotai";
import { languageModeAtom } from "../../atoms/language";
import { TranslationObj } from "../Home/Home";
import { Trans, useTranslation } from "react-i18next";

interface SuccessState {
  messageKind: string;
}

const path = "pages.main-success";
const keys = ["message"];

export default function Success({ messageKind }: SuccessState) {
  // const navigate = useNavigate();
  // const onMoveHome = () => {
  //   state === "signUp" && navigate("/main");
  //   state === "register" && navigate("/");
  //   state === "buildingDelete" && navigate("/main");
  //   state === "AddTimeSelect" && navigate("/main");
  // };
  const [content, setContent] = useState<TranslationObj>({});
  const currentLanguage = useAtomValue(languageModeAtom);
  const { t } = useTranslation();

  useEffect(() => {
    const translationObj: TranslationObj = {};
    keys.forEach((key) => {
      const keyArray = key.split(".");
      if (keyArray.length > 1) {
        if (!translationObj[keyArray[0]]) {
          translationObj[keyArray[0]] = {};
        }
        translationObj[keyArray[0]][keyArray[1]] = t(`${path}.${key}`);
        return;
      }
      translationObj[key] = t(`${path}.${key}`);
    });
    setContent(translationObj);
  }, [currentLanguage]);
  return (
    <>
      <SuccessContainer>
        <img src={SuccessIcon} alt="mail-icon" />
        <span>
          <Trans>{content.message}</Trans>
        </span>
      </SuccessContainer>
    </>
    // {/* <div
    //   style={{
    //     display: "flex",
    //     flexDirection: "column",
    //     width: "100%",
    //     height: "100%",
    //     overflow: "hidden",
    //     alignItems: "center",
    //     backgroundColor: "white",
    //   }}
    // >
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "start",
    //       flexDirection: "column",
    //       position: "absolute",
    //       overflow: "hidden",
    //       width: "100%",
    //       height: "100%",
    //       alignItems: "center",
    //     }}
    //   >
    //     <FszImage
    //       src={City}
    //       style={{
    //         backgroundImage:
    //           "linear-gradient(334deg, #FFD4C5 9.74%, #FEADB2 98.61%)",
    //         position: "relative",
    //         height: "35%",
    //         width: "255%",
    //       }}
    //     />
    //   </div>
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "start",
    //       flexDirection: "column",
    //       position: "absolute",
    //       overflow: "hidden",
    //       width: "100%",
    //       height: "100%",
    //       alignItems: "center",
    //     }}
    //   >
    //     <div
    //       style={{
    //         display: "flex",
    //         height: "40%",
    //         width: "100%",
    //         flexDirection: "column",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <div
    //         style={{
    //           display: "flex",
    //           height: "10%",
    //           width: "100%",
    //           flexDirection: "row",
    //           alignItems: "start",
    //           justifyContent: "start",
    //         }}
    //       >
    //         <FszImage
    //           src={BDAppLogo}
    //           style={{
    //             display: "flex",
    //             alignSelf: "start",
    //             justifySelf: "start",
    //             height: "100%",
    //             width: "25%",
    //             marginLeft: "0.5rem",
    //             marginTop: "0.5rem",
    //           }}
    //         />
    //       </div>

    //       <FszImage
    //         src={FszIcon}
    //         style={{
    //           height: "90%",
    //           width: window.innerHeight > window.innerWidth ? "40%" : "10%",
    //         }}
    //       />
    //     </div>
    //     <div
    //       style={{
    //         display: "flex",
    //         height: "60%",
    //         flexDirection: "column",
    //         alignItems: "center",
    //         justifyContent: "start",
    //       }}
    //     >
    //       {messageKind === "signUp" && (
    //         <>
    //           <FszImage
    //             src={SuccessIcon}
    //             style={{
    //               marginBottom: "1rem",
    //               height: "14%",
    //               width: "40%",
    //             }}
    //           />
    //           <PhoneNumberLabel
    //             style={{
    //               fontWeight: "500",
    //               lineHeight: "1.4",
    //               justifySelf: "start",
    //               fontFamily: "SUITE",
    //             }}
    //           >
    //             등록하신 번호로 문자를
    //             <br />
    //             전송하였습니다.
    //             <br />
    //             확인 후 절차를 완료해주세요!
    //           </PhoneNumberLabel>
    //         </>
    //       )}
    //     </div>
    //   </div>
    // </div> */}
  );
}

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  margin-top: 4rem;
  img {
    width: 90px;
    margin-bottom: 1rem;
  }

  span {
    width: 100%;
    max-width: 400px;

    font-family: "SUITE";
    font-weight: 600;
    line-height: 1.7rem;
    font-size: 1.3rem;

    word-wrap: break-word;
    word-break: keep-all;
  }
`;
