import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

export const getCurrentDateString = (): string =>
  dayjs().tz().format("MM월 DD일 HH시 mm분");
export const getCurrentDate = (): Date => dayjs().tz().toDate();
export const getTimeDiff = (time1: Date, time2: Date): number => {
  const timeDiff = Math.abs(time2.getTime() - time1.getTime());
  // var minutesDiff = Math.round(timeDiff / (1000 * 60));
  return timeDiff;
};