import axios from "axios";
import MockAdapter from "axios-mock-adapter";

const mock = new MockAdapter(axios);

mock.onGet("/zone/admin/1").reply(200, [
  {
    id: 1,
    buildingId: 1,
    floor: "1층",
    unit: "101호",
    phoneNumber: "010-2345-2345",
  },
  {
    id: 2,
    buildingId: 1,
    floor: "3층",
    unit: "301호",
    phoneNumber: "010-3456-3456",
  },
  {
    id: 3,
    buildingId: 1,
    floor: "4층",
    unit: "401호",
    phoneNumber: "010-4567-4567",
  },
  {
    id: 4,
    buildingId: 1,
    floor: "2층",
    unit: "201호",
    phoneNumber: "010-1234-1234",
  },
  {
    id: 5,
    buildingId: 1,
    floor: "1층",
    unit: "101호",
    phoneNumber: "010-2345-2345",
  },
  {
    id: 6,
    buildingId: 1,
    floor: "3층",
    unit: "301호",
    phoneNumber: "010-3456-3456",
  },
  {
    id: 7,
    buildingId: 1,
    floor: "4층",
    unit: "401호",
    phoneNumber: "010-4567-4567",
  },
  {
    id: 8,
    buildingId: 1,
    floor: "2층",
    unit: "201호",
    phoneNumber: "010-1234-1234",
  },
  {
    id: 9,
    buildingId: 1,
    floor: "1층",
    unit: "101호",
    phoneNumber: "010-2345-2345",
  },
  {
    id: 10,
    buildingId: 1,
    floor: "3층",
    unit: "301호",
    phoneNumber: "010-3456-3456",
  },
  {
    id: 11,
    buildingId: 1,
    floor: "4층",
    unit: "401호",
    phoneNumber: "010-4567-4567",
  },
  {
    id: 12,
    buildingId: 1,
    floor: "2층",
    unit: "201호",
    phoneNumber: "010-1234-1234",
  },
  {
    id: 13,
    buildingId: 1,
    floor: "1층",
    unit: "101호",
    phoneNumber: "010-2345-2345",
  },
  {
    id: 14,
    buildingId: 1,
    floor: "3층",
    unit: "301호",
    phoneNumber: "010-3456-3456",
  },
  {
    id: 15,
    buildingId: 1,
    floor: "2층",
    unit: "201호",
    phoneNumber: "010-1234-1234",
  },
]);
