import { api } from "./baseURL";
import {
  Building,
  SendMessageRequest,
  SendMessageResponse,
  User,
  UserBuilding,
  UserBuildingHistoryResponse,
  UserBuildingResponse,
  UserBuildingSignUpRequest,
  UserBuildingSignUpResponse,
} from "../Models/User";
import {
  DeleteUserBuildingResponse,
  ExtendTimeRequest,
  ExtendTimeResponse,
} from "../Models/Edit";
import {
  AlarmHistoryReponse,
  AlarmReactionResponse,
  CommentRequest,
  CommentResponse,
  GetBuildingUserResponse,
  MyReactionRequest,
  Reaction,
  TotalCommentResponse,
  TotalReactionResponse,
  UserCreateRequest,
  UserCreateResponse,
  UserDeleteRequest,
  UserDeleteResponse,
  UserImportRequest,
  UserUpdateRequest,
  UserUpdateResponse,
  GetBuildingUserCountResponse,
  UserImportResponse,
} from "../Models/Alarm";

// 존재하는 번호인지 확인 후 메세지 전송
export const sendMessageAPI = async (
  requestData: SendMessageRequest
): Promise<SendMessageResponse> =>
  (await api.post<SendMessageResponse>(`user/sendMessage`, requestData)).data;

// 전화번호 등록
export const userBuildingSignUpAPI = async (
  requestData: UserBuildingSignUpRequest
): Promise<UserBuildingSignUpResponse> =>
  (
    await api.post<UserBuildingSignUpResponse>(
      `user/userBuildingSignUp`,
      requestData
    )
  ).data;

// 건물 시간 연장
export const extendTimeAPI = async (
  request: ExtendTimeRequest
): Promise<ExtendTimeResponse> =>
  (await api.put<ExtendTimeResponse>(`building/extend`, request)).data;

export const deleteUserBuildingAPI = async (
  GUID: string
): Promise<DeleteUserBuildingResponse> =>
  (await api.delete<DeleteUserBuildingResponse>(`building?GUID=${GUID}`)).data;

//화재 알람 표시
export const alarmHistoryAPI = async (
  GUID: string
): Promise<AlarmHistoryReponse> =>
  (await api.get<AlarmHistoryReponse>(`alarm/${GUID}`)).data;

// 좋/싫
export const reactionAPI = async (
  reactionData: Reaction
): Promise<AlarmReactionResponse> =>
  (await api.post<AlarmReactionResponse>(`alarm/reaction`, reactionData)).data;

// 댓글
export const commentAPI = async (
  commentData: CommentRequest
): Promise<CommentResponse> =>
  (await api.post<CommentResponse>(`alarm/comment`, commentData)).data;

// 댓글 조회
export const getCommentsAPI = async (
  alarmHistoryID: number
): Promise<TotalCommentResponse[]> =>
  (await api.get<TotalCommentResponse[]>(`alarm/getComment/${alarmHistoryID}`))
    .data;

export const getMyLastReactionAPI = async (
  request: MyReactionRequest
): Promise<Reaction> =>
  (await api.post<Reaction>(`alarm/getMyReaction`, request)).data;

// 반응 조회
export const getReactionsAPI = async (
  alarmHistoryID: number
): Promise<TotalReactionResponse> =>
  (await api.get<TotalReactionResponse>(`alarm/getReaction/${alarmHistoryID}`))
    .data;

export const getCurrentBuildingInfoAPI = async (
  GUID: string
): Promise<UserBuildingResponse> =>
  (await api.get<UserBuildingResponse>(`UserBuilding?GUID=${GUID}`)).data;

export const getUserBuildingHistoryAPI = async (
  GUID: string
): Promise<UserBuildingHistoryResponse> =>
  (
    await api.get<UserBuildingHistoryResponse>(
      `UserBuilding/history?GUID=${GUID}`
    )
  ).data;

export const getCurrentBuildingAPI = async (
  buildingID: number
): Promise<Building> =>
  (await api.get<Building>(`Building/${buildingID}`)).data;

// 건물에 속한 사용자 조회
export const getBuildingUsers = async (
  buildingID: number,
  skip: number,
  take: number
): Promise<GetBuildingUserResponse> =>
  (
    await api.get<GetBuildingUserResponse>(
      `User/BuildingUsers/${buildingID}?Skip=${skip}&Take=${take}`
    )
  ).data;

// 건물 사용자 추가
export const addBuildingUser = async (
  request: UserCreateRequest
): Promise<UserCreateResponse> =>
  (await api.post<UserCreateResponse>(`User/Add`, request)).data;

// 건물 사용자 수정
export const updateBuildingUser = async (
  request: UserUpdateRequest
): Promise<UserUpdateResponse> =>
  (await api.post<UserUpdateResponse>(`User/Update`, request)).data;

//건물 사용자 제거
export const deleteBuildingUser = async (
  request: UserDeleteRequest
): Promise<UserDeleteResponse> =>
  (await api.post<UserDeleteResponse>(`User/Delete`, request)).data;

// 건물 전체 사용자 수 조회
export const getBuildingUserCount = async (
  buildingId: number
): Promise<GetBuildingUserCountResponse> =>
  (
    await api.get<GetBuildingUserCountResponse>(
      `User/BuildingUserCount/${buildingId}`
    )
  ).data;

export const importBuildingUser = async (
  request: FormData
): Promise<UserImportResponse> =>
  (
    await api.post<UserImportResponse>(`User/Import`, request, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  ).data;
