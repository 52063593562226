import axios from "axios";

export const api = axios.create({
  //   baseURL: `${process.env.REACT_APP_API}`,
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: `https://fszapi.bdapp.ai/api`,
  // baseURL: `http://192.168.0.241:5010/api`,
});

api.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem("accessToken");

  accessToken && (config.headers.Authorization = `Bearer ${accessToken}`);

  return config;
});
