import { ReactNode } from "react";
import styled from "styled-components";

export const VerticalAlignment = {
  Start: 0,
  Center: 1,
  End: 2,
  Fill: 3
} as const;
export type VerticalAlignment =
  (typeof VerticalAlignment)[keyof typeof VerticalAlignment];

export const HorizentalAlignment = {
  Start: 0,
  Center: 1,
  End: 2,
  Fill: 3
} as const;
export type HorizentalAlignment =
  (typeof HorizentalAlignment)[keyof typeof HorizentalAlignment];

  export const ArrangeDirection = {
    LeftToRight: 0,
    TopToBottom: 1,
  } as const;
  export type ArrangeDirection =
    (typeof ArrangeDirection)[keyof typeof ArrangeDirection];

interface BlockProps {
  children: ReactNode;
  Width: string;
  Height: string;
  VerticalAlignment: VerticalAlignment;
  HorizentalAlignment: HorizentalAlignment;
  ArrangeDirection: ArrangeDirection;
  BackgroundColor: string;
}

export const Block = styled.div<BlockProps>`
    display: flex;
    background-color: ${props => (props.BackgroundColor)};
    width: ${props => (props.Width)};
    height: ${props => (props.Height)};
    flex-direction: ${props => (props.ArrangeDirection === ArrangeDirection.LeftToRight ? "row" : "column")};
    justify-content: ${props => (
        props.HorizentalAlignment === HorizentalAlignment.Start
        ?
        "flex-start"
        :
        props.HorizentalAlignment === HorizentalAlignment.End
        ?
        "flex-end"
        :
        props.HorizentalAlignment === HorizentalAlignment.Center
        ?
        "center"
        :
        "stretch"
    )};
    align-items: ${props => (
        props.VerticalAlignment === VerticalAlignment.Start
        ?
        "flex-start"
        :
        props.VerticalAlignment === VerticalAlignment.End
        ?
        "flex-end"
        :
        props.VerticalAlignment === VerticalAlignment.Center
        ?
        "center"
        :
        "stretch"
    )};
`;

export const LayoutBlock = ({Width, Height, VerticalAlignment, HorizentalAlignment, ArrangeDirection, BackgroundColor, children}: BlockProps) => {
  return (
    <Block style={{overflow: "hidden"}} BackgroundColor={BackgroundColor} Width={Width} Height={Height} VerticalAlignment={VerticalAlignment} HorizentalAlignment={HorizentalAlignment} ArrangeDirection={ArrangeDirection}>
      {children}
    </Block>
  );
};

LayoutBlock.defaultProps = {
  Width: "100px",
  Height: "50px",
  VerticalAlignment: VerticalAlignment.Fill,
  HorizentalAlignment: HorizentalAlignment.Start,
  ArrangeDirection: ArrangeDirection.LeftToRight,
  BackgroundColor: "#fffff"
}

export const Title = styled.h1`
  font-size: 2.75rem;
  font-family: "TheJamsil";
  line-height: normal;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #000000;
`;

export const SubTitle = styled.h2`
  color: #a7a7a7;
  font-family: "TheJamsil";
  font-size: 2rem;
  font-weight: 500;
  line-height: normal;
`;

export const CheckButton = styled.button`
  display: flex;
  width: 35%;
  font-size: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #000;
  border: none;
  color: white;
  font-family: "SUITE";
`;

export const FszImage = styled.img`
  justify-self: center;
  align-items: center;
`;

interface ButtonProps {
  children: ReactNode | string | null;
  TextColor: string;
  BackgroundColor: string;
  BorderRadius: string;
  Width: string;
  Height: string;
  IsSelected: boolean;
  IsDisabled: boolean;
  clicked?: () => void | null;
}

interface FszButtonStyleProps {
  TextColor: string,
  BackgroundColor: string,
  BorderRadius: string,
  Width: string;
  Height: string;
  IsSelected: boolean;
}

const FszButtonStyle = styled.button<FszButtonStyleProps>`
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: ${props => (props.Width)};
  height: ${props => (props.Height)};
  border-radius: ${props => (props.BorderRadius)};
  background-color: ${props => (props.BackgroundColor)};;
  border: none;
  color: ${props => (props.TextColor)};;
  font-family: "SUITE";
`;

export const FszButton = ({
  children = null,
  clicked = () => {},
  Width = "300px",
  Height = "50px",
  TextColor = "#ffffff",
  BackgroundColor = "#454545",
  BorderRadius = "100px",
  IsSelected = false,
  IsDisabled = false,
}: ButtonProps) => {
  return (
<FszButtonStyle
        Width={Width}
        Height={Height}
        IsSelected={IsSelected}
        BorderRadius={BorderRadius}
        BackgroundColor={BackgroundColor}
        TextColor={TextColor}
        onClick={clicked}
        disabled={IsDisabled}
      >
        {children}
      </FszButtonStyle>
  );
};

FszButton.defaultProps = {
  children: null,
  clicked: () => {},
  TextColor: "#ffffff",
  BackgroundColor: "#000000",
  BorderRadius: "100px",
  Width: "300px",
  Height: "90px",
  IsSelected: false,
  IsDisabled: false,
};

export const FszInput = styled.input`
  width: 100%;
  font-size: 1.5rem;
  font-weight: 300;
  font-family: "SUITE";
  padding: 0.5rem;
  font-size: 1.5rem;
  border: none;
  display: flex;
  text-align: left;
  border-bottom: 2px solid #000;
  outline: none;

  &::-webkit-inner-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &::placeholder {
    font-size: 18px;
    color: #b4b4b4;
  }
`;

