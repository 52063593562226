import {
  Overlay,
  Modal,
  MessageContainer,
  Message,
  SubMessage,
  ButtonContainer,
  ConfirmButton,
} from "./styles";

interface Props {
  setIsAddUserCheckModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUserCheckModal = ({ setIsAddUserCheckModalOpen }: Props) => {
  return (
    <Overlay onClick={() => setIsAddUserCheckModalOpen((prev) => !prev)}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <MessageContainer>
          <Message>내용을 입력해주세요</Message>
          <SubMessage>* 연락처는 10~11자리로 입력해주세요</SubMessage>
        </MessageContainer>
        <ButtonContainer>
          <ConfirmButton
            onClick={() => setIsAddUserCheckModalOpen((prev) => !prev)}
          >
            확인
          </ConfirmButton>
        </ButtonContainer>
      </Modal>
    </Overlay>
  );
};

export default AddUserCheckModal;
