import { User } from "./User";

export const CommentResults = {
  Success: 0,
  Error: 1,
} as const;
export type CommentResults =
  (typeof CommentResults)[keyof typeof CommentResults];

export const AlarmReactionResults = {
  Success: 0,
  Error: 1,
} as const;
export type AlarmReactionResults =
  (typeof AlarmReactionResults)[keyof typeof AlarmReactionResults];

export interface CommentRequest {
  writeNumber: number;
  commentContent: string;
  topAnchor: string;
  guid: string;
  alarmHistoryID: number;
  phoneNumber: string;
}

export interface CommentResponse {
  resultState: CommentResults;
  message: string;
}

export interface TotalCommentResponse {
  guid: string;
  commentContent: string;
  startTime: Date;
  userNumber: string;
}

export interface Reaction {
  guid: string;
  like: number;
  dislike: number;
  alarmHistoryID: number;
}

export interface AlarmReactionResponse {
  resultState: AlarmReactionResults;
  message: string;
}

export interface TotalReactionResponse {
  totalLike: number;
  totalDislike: number;
}

export interface MyReactionRequest {
  alarmHistoryID: number;
  guid: string;
}

export interface AlarmHistoryReponse {
  id: number;
  buildingName: string;
  buildingID: number;
  location: string;
  startTime: Date;
  endTime: Date | null;
}

export interface UserCreateRequest {
  buildingId: number;
  phoneNumber: string;
  buildingNumber: string; //동
  unit: string; //호수
}

export interface UserCreateResponse {
  Result: number; //0:Successful, 1:AlreadyExist, 3:Invalid 4:Error
}

export interface UserUpdateRequest {
  id: number;
  buildingId: number;
  phoneNumber: string;
  buildingNumber: string;
  unit: string;
}

export interface UserUpdateResponse {
  Result: UserUpdateResult;
}

export enum UserUpdateResult {
  successful,
  invalid,
  error,
}

export interface UserDeleteRequest {
  buildingId: number;
  userId: number;
}

export interface UserDeleteResponse {
  Result: UserDeleteResult;
}

export enum UserDeleteResult {
  succesful,
  error,
}

export interface GetBuildingUserResponse {
  result: GetBuildingResult; //0:
  users: Array<User>;
}

export enum GetBuildingResult {
  successful,
  error,
}

export interface GetBuildingUserCountResponse {
  result: GetBuildingUserCountResult;
  count: number;
}

export enum GetBuildingUserCountResult {
  successful,
  invalid,
  error,
}

export interface UserImportRequest {
  buildingId: number;
  file: File;
}

export interface UserImportResponse {
  result: UserImportResult;
}

export enum UserImportResult {
  successful,
  invalid,
  error,
}
