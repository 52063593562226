import { Navigate, Outlet, useLocation } from "react-router-dom";
import userManager, { isLoggedIn, onLogin } from "./AuthManager";
import React, { useEffect, useState } from "react";
import { removeLocal, setLocal } from "./LocalStorageHelper";

interface Props {
    userData:boolean;
}

const AuthWrapper = ({userData}:Props) => {
    const location = useLocation();
    setLocal('prevLocation', location.pathname);

    if(userData)
        {
            removeLocal('prevLocation');
            return <Outlet />;
        }
        else
        {
            return <Navigate to={"/login"}></Navigate>
        }
  };
  
  export default AuthWrapper;