import {
  Overlay,
  Modal,
  Message,
  ButtonContainer,
  CancelButton,
  ConfirmButton,
} from "./styles";

interface Props {
  setIsRemoveUserConfirmModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleRemoveUsers: () => void;
}

const RemoveConfirmModal = ({
  setIsRemoveUserConfirmModalOpen,
  handleRemoveUsers,
}: Props) => {
  const onClickCancelButton = () => {
    setIsRemoveUserConfirmModalOpen(false);
  };

  const onClickOverlay = () => {
    setIsRemoveUserConfirmModalOpen(false);
  };

  return (
    <Overlay onClick={onClickOverlay}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <Message>선택한 정보를 삭제하시겠습니까?</Message>
        <ButtonContainer>
          <CancelButton onClick={onClickCancelButton}>취소</CancelButton>
          <ConfirmButton
            onClick={() => {
              handleRemoveUsers();
              setIsRemoveUserConfirmModalOpen(false);
            }}
          >
            확인
          </ConfirmButton>
        </ButtonContainer>
      </Modal>
    </Overlay>
  );
};

export default RemoveConfirmModal;
