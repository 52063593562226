import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.2);
`;

export const Modal = styled.div`
  display: flex;
  width: 350px;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 600px) {
    width: 80%;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Message = styled.span`
  color: #000;
  font-family: "Gothic A1";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.8px;

  @media screen and (max-width: 600px) {
    font-size: 1.1rem;
  }
`;

export const SubMessage = styled.span`
  color: rgb(164, 167, 175);
  font-family: "Gothic A1";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.8px;

  @media screen and (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const CancelButton = styled.button`
  border: none;
  width: 160px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #f15a63;
  background: #fff;

  color: #f15a63;
  text-align: center;
  font-family: "Gothic A1";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem;
  }
`;

export const ConfirmButton = styled.button`
  border: none;
  width: 160px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f15a63;

  color: #fff;
  text-align: center;
  font-family: "Gothic A1";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem;
  }
`;
